$primary-color: #0384e5;
.pricing-title {
	color: #0584e5;
	text-align: center;
	font-family: Gotham;
	list-style: 3rem;
	margin: 7rem 0;
	font-size: 5rem;
	@media (max-width: 769px) {
		line-height: 4rem;
		margin: 7rem 0;
		font-size: 4rem;
	}
	@media (max-width: 500px) {
		margin: 15% 0;
		padding: 0 5px;

		height: 30vh;
		vertical-align: middle;
		display: table-cell;
	}
}
.pricing-div {
	width: 100%;
}
.pricing-section-container {
	/*height: 100vh;*/

	@media (max-width: 745px) {
		height: 100%;
	}

	.pricing-section {
		position: relative;
		height: 100%;

		img.pricingBg {
			width: 100vw;
			height: fit-content;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: -1;
			@media (max-width: 768px) {
				height: inherit;
			}
		}

		h2 {
			text-align: center;
			font-size: 5rem;
			color: var(--darkBlue);
			font-weight: bold;
			margin: 5rem auto;

			@media (max-width: 500px) {
				font-size: 2.3rem;
				position: relative;
			}
		}

		.pricing-container {
			width: 100vw;

			@media (max-width: 500px) {
			}
		}

		.cases {
			display: flex;
			justify-content: space-around;
			align-items: flex-end;
			margin: 0rem 15rem;
			margin-bottom: 5rem;

			@media (max-width: 1300px) {
				margin: 0rem 10rem;
			}

			@media (max-width: 1200px) {
				margin: 0rem 5rem;
			}

			@media (max-width: 745px) {
				flex-direction: column;
				margin: 5% !important;
			}
			@media (max-width: 768px) {
				margin: 0 5%;
			}
			@media (max-width: 500px) {
				.aos-init {
					width: 100%;
				}
			}
		}

		.pricing-card {
			background: #fff;
			border-radius: 2rem;
			height: 30rem;
			position: relative;
			max-width: 33rem;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			transition: all 0.5s ease;
			@media (max-width: 1120px) {
				max-width: 350px;
			}
			@media (max-width: 768px) {
				width: 95%;
			}
			@media (max-width: 500px) {
				max-width: unset;
				width: 100%;
				margin: 10% 0;
			}
		}

		.pricing-card-side {
			box-shadow: 19px 28px 30px rgb(38 38 38 / 20%);
			height: 100%;

			@media (max-width: 1200px) {
				width: 300px;
				margin: auto;
			}
			@media (max-width: 1100px) {
				width: 270px;
				margin: auto;
			}
			@media (max-width: 920px) {
				width: 240px;
				margin: auto;
			}
			@media (max-width: 835px) {
				width: 220px;
				margin: auto;
			}
			@media (max-width: 700px) {
				width: 200px;
				margin: auto;
			}
			@media (max-width: 745px) {
				width: 100%;
				margin: 40px auto !important;
			}

			&:hover {
				box-shadow: 19px 28px 30px rgb(38 38 38 / 30%);
			}
		}

		.centre-orange {
			position: absolute;
			top: -5rem;
			right: 0;
			left: 0;
			width: 100%;
			height: 7rem;
			border-radius: 2rem 2rem 0rem 0rem;
			background: #10b0df;
			text-align: center;
			z-index: -100;

			@media (max-width: 745px) {
				z-index: 0;
				border: 3px solid #098fe3;
			}

			p {
				padding: 1rem;
				color: var(--white);
				font-size: 2rem;
				font-family: Gotham-light-italic;
				line-height: 4rem;
			}
		}

		.pricing-card-center {
			box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
			height: 75rem;
			max-width: 33rem;
			z-index: -1;

			&:hover {
				box-shadow: 15px 30px 30px rgb(38 38 38 / 20%);
			}

			.main-blue.center {
				position: absolute !important;
				top: 3.5rem;
				width: 100%;
				border-radius: 2rem 2rem 0rem 0rem;
			}

			.description-access {
				position: absolute;
				top: 31rem;
				right: 0;
				left: 0;
				width: 100%;
			}

			.cases-view {
				display: inline-block;
				margin-right: 3rem;
				color: var(--orange);
				font-size: 2rem;
				line-height: 3.8rem;
				border-radius: 1rem;
				-webkit-transition: all 0.3s;
				transition: all 0.3s;
				padding: 1rem 2rem;
				text-align: center;
				position: relative;

				&:before {
					content: "";
					border-bottom: 1px solid var(--orange);
					padding: 0 6.3rem;
					position: absolute;
					bottom: 1.5rem;
					left: 0;
					right: 0;
					margin: 0 auto;
					width: 0;
					-webkit-transition: all 0.3s;
				}

				&:hover {
					background: var(--darkBlue);
					color: var(--orange);
					box-shadow: 0 0.4rem 0.4rem rgba(83, 100, 255, 0.32);

					&:before {
						border-bottom: 2px solid var(--orange);
					}
				}
			}
		}

		.main-blue {
			border-radius: 2rem 2rem 0rem 0rem;
			background: #0584e5;
			display: flex;
			flex-direction: column;
			text-align: center;
			justify-content: space-around;
			align-items: center;
			height: 40%;
			position: relative;
		}

		.bronze {
			background: #ffab4c;
		}

		.silver {
			background: #8869d2;
		}

		.pricing-type {
			color: var(--white);
			font-size: 2.5rem;
			margin: 2rem 0 1rem 0;
			@media (max-width: 768px) {
				font-size: 2rem;
			}
			@media (max-width: 500px) {
				font-size: 3.5rem;
			}
		}

		.price {
			color: var(--white);
			font-size: 3rem;
			font-weight: bold;
			line-height: 2rem;
			margin: 2rem 0;
			@media (max-width: 768px) {
				font-size: 2rem;
			}
			.learner {
				font-size: 2rem;
				
				color: #ffffffc2;
				font-weight: 100;
				@media (max-width: 500px) {
					font-size: 2.75rem;
				}
			}
			@media (max-width: 768px) {
				font-size: 1.75rem;
			}
			@media (max-width: 500px) {
				font-size: 2.75rem;
			}
		}

		img {
			width: 7rem;
			height: 7rem;
			margin: 1rem 0;
		}
	}

	.description-access {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		text-align: center;
		align-items: center;
		height: 60%;
	}

	.pricing-description {
		text-align: center;
		font-size: 1.6rem;
		padding: 2rem;
		color: #757575;
		width: 350px;
		line-height: 30px;
		@media (max-width: 920px) {
			width: 240px;
			margin: auto;
			font-size: 12px;
		}

		@media (max-width: 768px) {
			font-size: 12px;
			padding: 0 10px;
			width: 100%;
		}
		@media (max-width: 500px) {
			font-size: 16px;
			padding: 10%;
			width: 100%;
		}
		ul {
			padding: 0;
			li {
				list-style: none;
			}
		}
	}

	.request {
		
		margin-bottom: 10%;
		display: inline-block;
		background-color: $primary-color;
		min-width: 179px;
		color: var(--white);
		text-decoration: none;
		font-size: 2rem;
		line-height: 3.8rem;
		border-radius: 1rem;
		-webkit-transition: all 1s;
		transition: all 1s;
		padding: 1rem 2rem;
		text-align: center;
		cursor: pointer;
		border: 0;
		box-shadow:1px 1px 2px 1px rgb(213, 209, 209);
		&:hover {
			background: $primary-color;
		}
	}
}
