$primary-color: #0384e5;

$font-lato: "Lato";

.learner-section-container {
    width: 100%;
    display: inline-flex;
    margin: auto;
    padding: 30px 10%;
    flex-direction: column;

    @media (max-width: 500px) {
        padding: 0;
        display: flex;
        flex-flow: column-reverse;
        align-items: center;
        justify-items: center;
        height: unset !important;
        justify-content: center;
        align-content: center;
        flex-wrap: nowrap;
        flex-direction: column-reverse;
    }

    @media (max-width: 769px) {
        padding: 0 5%;
        // height: 100vh;
        // padding: 0;
        display: flex;
        flex-flow: column-reverse;
        align-items: center;
        justify-items: center;
        height: unset !important;
        justify-content: center;
        align-content: center;
        flex-wrap: nowrap;
        flex-direction: column;
    }

    .learner-section-left-container {
        width: 50%;
        display: inline-grid;
        align-items: baseline;
        justify-content: space-evenly;
        align-content: space-evenly;
        justify-items: flex-start;

        @media (max-width: 501px) {
            width: 100%;
            padding: 0;
        }

        @media (max-width: 1000px) {
            width: 50%;
            display: flex;
            justify-items: end;
            flex-direction: column;
            justify-content: space-evenly;
        }

        @media (max-width: 750px) {
            width: 100%;
            display: flex;
            justify-items: end;
            flex-direction: column;
            justify-content: center;
        }

        @media (max-width: 920px) {
            width: 100%;
            // display: flex;
            // justify-items: end;
            // flex-direction: column;
            // justify-content: center;
        }

        .learner-section-header {}

        .learner-section-title {
            color: $primary-color;
            font-weight: 800;
            font-size: 5rem;

            @media (max-width: 500px) {
                font-size: 4rem;
                text-align: center;
            }

            @media (max-width: 769px) {
                font-size: 4rem;
                text-align: center;
            }

            @media (max-width: 1000px) {
                font-size: 4rem;
            }
        }

        .learner-section-paragraph {
            color: $primary-color;
            font-size: 3.5rem;
            line-height: 1;
            /*font-style: italic;*/
            font-family: Gotham-light; //-italic;

            @media (max-width: 500px) {
                font-size: 3rem;
                text-align: center;
            }

            @media (max-width: 769px) {
                font-size: 3rem;
                color: #0384e5;
                line-height: 2.5rem;
                text-align: center;
                /*font-style: italic;*/
                font-family: Gotham-light; //-italic;
                margin-top: 25px;
            }
        }

        .learner-section-sector {
            margin: 2rem auto;
            display: inline-flex;
            width: 100%;

            @media (max-width: 500px) {
                width: 100%;
                margin: 0 !important;
            }

            @media (max-width: 769px) {
                margin: 1rem auto;
            }

            .learner-sector-container {
                display: inline-flex;
                width: 50%;

                @media (max-width: 769px) {
                    padding: 10px 0;
                    display: flex;
                    width: 50%;
                    flex-direction: column;
                    flex-wrap: wrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                }

                @media (max-width: 330px) {
                    padding: 10px 0;
                }

                .learner-sector-img {
                    width: 30%;
                    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
                    cursor: pointer;

                    @media (max-width: 769px) {
                        width: 50%;
                        margin-bottom: 10%;
                    }

                    &:hover {
                        // box-shadow: 19px 28px 30px rgb(38 38 38 / 30%);
                        transform: scale(1.05);
                    }
                }

                .learner-sector-txt {
                    margin: auto 0;
                    color: #757575;
                    font-size: 1.85rem;
                    width: 15rem;
                    padding: 0 20px;
                    line-height: 2rem;
                    font-weight: 800;
                    font-family: "Gotham-Light";
                    font-style: $font-lato;

                    // @media (max-width: 769px) {
                    //     padding: 0;
                    //     text-align: center;
                    //     margin: auto;
                    //     font-size: 18px !important;
                    // }

                    // @media (max-width: 1100px) {
                    //     padding: 0 5px;
                    //     font-size: 16px;
                    // }

                    // @media (max-width: 900px) {
                    //     font-size: 14px !important;
                    // }

                    // @media (max-width: 1000px) {
                    //     padding: 0 5px;
                    //     font-size: 16px !important;
                    // }

                    // @media (max-width: 501px) {
                    //     font-size: 14px !important;
                    // }

                    @media (max-width: 786px) {
                        // width: 100%;
                        // display: flex;
                        // justify-items: end;
                        // flex-direction: column;
                        // justify-content: center;
                        // line-height: 16px;
                    }
                }
            }
        }
    }

    .learner-section-right-container {
        width: 50%;
        height: 100%;
        margin: auto;
        text-align: center;

        @media (max-width: 920px) {
            width: 100% !important;
        }

        @media (max-width: 1120px) {
            height: unset;
            width: 50%;
        }

        .learner-sector-img {
            padding: 5%;
            width: 100%;
            display: inline-grid;
            align-items: stretch;
            align-content: space-around;
            justify-content: center;

            @media (max-width: 1313px) {
                padding: 0;
            }

            @media (max-width: 1120px) {
                padding: unset;
            }

            @media (max-width: 769px) {
                padding: 0 15%;
            }

            @media (max-width: 500px) {
                padding: 0 15%;
                margin-top: 100px;
            }
        }
    }

    .lc-header {
        width: 100%;
        margin: 10px 20px;

        .lc-title {
            color: $primary-color;
            margin: 0;
        }

        .lc-sub-title {
            color: #757575;
            width: 100%;
            margin-bottom: 10px;
            text-align: start;
            line-height: 2rem;
            font-weight: 800;
            font-size: 18px;
            font-family: "Gotham-Light";
        }
    }

    .lc-card-group {
        display: flex;

        @media (max-width: 769px) {
            flex-direction: column;
            width: 100%;
        }

        .lc-card {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 30%;
            background: #E1ECFA;
            box-shadow: 1px 1px 6px 1px #d8e7fd8a;
            border-radius: 15px;
            margin: 20px;
            padding: 0 20px;
            height: 500px;
            transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);

            &:hover {
                transform: scale(1.05);
            }

            @media (max-width: 1200px) {
                height: 417px;
            }

            @media (max-width: 1000px) {
                height: 380px;
            }

            @media (max-width: 887px) {
                height: 325px;
            }

            @media (max-width: 852px) {
                padding: 0 15px;
            }

            @media (max-width: 815px) {
                margin: 15px;
            }

            @media (max-width: 650px) {
                font-size: 16px;
            }

            @media (max-width: 500px) {
                font-size: 18px;
            }

            @media (max-width: 769px) {
                text-align: center;
                width: 100%;
                margin: 20px 0;
                height: auto;
                font-size: 18px;
                padding: 15px 15px 40px;
            }

            .lc-img-container {
                height: 75%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                @media (max-width: 1200px) {
                    height: 70%;
                }

                @media (max-width: 1000px) {
                    height: 67%;
                }

                @media (max-width: 887px) {
                    height: 65%;
                }

                @media (max-width: 768px) {
                    height: auto;
                }

                .lc-img {
                    width: 100%;
                    max-height: 330px;
                    margin: 20px 0;
                }
            }

            .lc-action-container {
                width: 100%;
                height: 25%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                .lc-txt {
                    color: #757575;
                    width: 100%;
                    margin-bottom: 10px;
                    text-align: center;
                    line-height: 2rem;
                    font-weight: 800;
                    font-size: 18px;
                    font-family: "Gotham-Light";
                    font-style: $font-lato;

                    @media (max-width: 1200px) {
                        font-size: 18px;
                    }

                    @media (max-width: 1000px) {
                        font-size: 17px;
                    }

                    @media (max-width: 887px) {
                        font-size: 16px;
                    }

                    @media (max-width: 777px) {
                        font-size: 15px;
                    }

                    @media (max-width: 769px) {
                        font-size: 18px;
                    }

                    @media (max-width: 500px) {
                        font-size: 18px;
                    }
                }

                .lc-button {
                    width: 100%;
                    padding: 10px;
                    cursor: pointer;
                    text-decoration: none;
                    border: 0;
                    border-radius: 10px;
                    background-color: #0384e5;
                    color: white;
                    font-size: 18px;
                    text-align: center;
                    font-weight: 800;
                    font-family: "Gotham-Light";
                    font-style: $font-lato;

                    @media (max-width: 1200px) {
                        font-size: 18px;
                    }

                    @media (max-width: 1000px) {
                        font-size: 17px;
                    }

                    @media (max-width: 887px) {
                        font-size: 16px;
                    }

                    @media (max-width: 777px) {
                        font-size: 15px;
                    }

                    @media (max-width: 769px) {
                        font-size: 18px;
                    }

                    @media (max-width: 500px) {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

.learner-sector-txt-content {
    display: flex;
    flex-direction: column;
}