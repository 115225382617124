$primary-color: #0584e5;

.steps-section-container {
	width: 100%;

	.section-container {
		width: 100%;
		// padding-top: 100px;

		.step-01-image {
			img {
				@media (max-width: 769px) {
					padding: 0 15% !important;
					max-height: 400px;
				}
			}
		}

		.step-image {
			img {
				padding: 10%;
				max-width: 100%;
				max-height: 500px;
				object-fit: contain;

				@media (max-width: 500px) {
					max-width: 100%;
					padding-left: 0rem;
					padding: 0rem 1rem;
					max-height: 800px;
				}
				@media (max-width: 768px) {
					max-width: 100%;
					max-height: 400px;
					padding: 0 5%;
				}
				@media (max-width: 1000px) {
					padding-inline-start: 5%;
					max-height: 400px;
				}
			}
		}

		.steps-section-title {
			margin-top: 3rem;
			text-align: center;

			@media (max-width: 500px) {
				margin-bottom: 0rem;
				padding: 0 10%;
			}

			.stepSectionTitle {
				font-weight: bold;
				color: $primary-color;
				font-size: var(--h1);

				@media (max-width: 500px) {
					font-size: 3rem;
				}
				@media (max-width: 768px) {
					font-size: 3rem;
				}
			}

			.stepSectionSubTitle {
				color: $primary-color;
				font-size: 3rem;
				/*font-style: italic;*/
				font-weight: lighter;
				font-family: Gotham-light; //-italic;
				margin-top: 0rem;

				@media (max-width: 500px) {
					font-size: 3rem;
					margin-top: 0rem;
					margin-bottom: 9rem;
				}
				@media (max-width: 768px) {
					font-size: 3rem;
				}
				@media (max-width: 1000px) {
					font-size: 3rem;
				}
			}
		}

		.step {
			display: flex;
			align-items: center;
			justify-content: center;
			/*height: 100vh;*/
			background: var(--white);

			@media (max-width: 500px) {
				flex-direction: column-reverse;
				margin-bottom: 5rem;
				height: 100%;
				margin-top: -10%;
			}

			@media (max-width: 769px) {
				flex-direction: column-reverse;
				padding: 50px 0 !important;
			}

			&.inverse {
				flex-direction: row-reverse;
				background: $primary-color;
				padding: 50px 10% !important;
				height: 100%;

				@media (max-width: 500px) {
					flex-direction: column-reverse;
					padding: 3rem 0;
					height: 100%;
				}

				.step-image {
					text-align: start;
					@media (max-width: 768px) {
						text-align: center;
					}
					img {
						// transform: rotate(15deg);
						max-width: 70%;
						object-fit: contain;
						@media (max-width: 500px) {
							max-width: 75%;
							// transform: rotate(0deg) !important;
							padding: 0;
						}
						@media (max-width: 768px) {
							// transform: rotate(15deg);
						}
					}
				}

				.content {
					width: 100%;

					@media (max-width: 500px) {
						max-width: 100%;
						// padding: 15px 10%;
					}
				}

				.step-number {
					color: var(--white);
				}

				.step-title {
					/*line-height: 1.21;*/
					color: var(--white);

					@media (max-width: 500px) {
						font-size: 3rem;
						padding: 15px;
						/*line-height: 1 !important;*/
					}
					@media (max-width: 768px) {
						/*line-height: 6rem;*/
						text-align: center !important;
					}
					@media (max-width: 1000px) {
						/*line-height: 1;*/
						padding: 0;
					}

					@media (min-width: 1001px) {
						/*line-height: 1;*/
						padding: 0;
					}
				}

				.description {
					color: var(--white);
					margin-left: 2.2rem;

					h3 {
						line-height: 4rem;
						padding-left: 7rem;
					}
				}
			}
		}

		.step01 {
			width: 100%;
			height: 100% !important;
			padding: 0 10%;
			@media (min-width: 769px) {
				display: flex;
				flex-direction: row-reverse;
			}
			@media (max-width: 769px) {
				padding: 0 10% !important;
			}
			@media (max-width: 500px) {
				padding: 0 !important;
			}
			.step-imgage01 {
				width: 100% !important;
				img {
					padding: 0 !important;
				}
			}
		}
		.content-01 {
			padding: 20px 0;
		}
		.content {
			max-width: 100%;
			display: flex;
			flex-direction: column;
			// padding: 0 10rem;

			@media (max-width: 500px) {
				padding: 0 1rem;
			}
			@media (max-width: 330px) {
				padding: 0 1rem !important;
			}

			@media (max-width: 768px) {
				font-size: 3rem;
				// padding: 0;
			}
			@media (max-width: 1000px) {
				// padding: 0 10%;
			}
		}

		.title {
			display: flex;
			align-items: center;
		}

		.step-number {
			font-size: 10rem;
			color: $primary-color;
			padding-right: 1.2rem;

			@media (max-width: 500px) {
				font-size: 15rem;
			}
		}

		.step-title {
			span.mobile-description {
				display: none;

				@media (max-width: 500px) {
					display: block;
				}
			}
			span.mobile-description-sub {
				font-size: 2rem;
				display: block;
				line-height: 2.5rem;
				margin-top: 2rem;
				font-family: "Gotham-light";
			}

			font-size: 5rem;
			font-weight: 800;
			color: $primary-color;

			@media (max-width: 850px) {
				font-size: 3rem !important;
			}
			@media (max-width: 500px) {
				font-size: 3rem;
				padding: 15px;

				span.mobile-description {
					font-size: 2rem;
					color: gray;
				}
			}

			@media (max-width: 768px) {
				font-size: 3rem;
				span.mobile-description {
					text-align: center;
					font-weight: 100;
					font-size: 2.5rem;
					margin-bottom: 5px;
				}
			}
		}

		.title {
			margin-bottom: 2rem;
			@media (max-width: 768px) {
				margin-bottom: 0;
				text-align: center;
			}
		}

		.description {
			font-size: 3rem;

			@media (max-width: 330px) {
				padding: 0 !important;
			}
			@media (max-width: 500px) {
				padding: 0 !important;
			}
			@media (max-width: 768px) {
				padding: 0 5%;
			}
			@media (max-width: 1000px) {
				padding: 0;
			}
			.step-text {
				line-height: 2.5rem;
				color: #757575;
				font-size: 2rem;
				font-weight: 800;
				font-family: "Gotham-light";

				@media (max-width: 500px) {
					display: none;
					text-align: center;
				}
				@media (max-width: 768px) {
					font-size: 2rem;
					text-align: center;
				}
			}
		}

		.description-2 {
			font-size: 3rem;

			.step-text {
				color: white;
				line-height: 2.5rem;
				font-size: 2.5rem;
				font-weight: 100;

				@media (max-width: 500px) {
					display: none;
				}
			}
		}

		.description-3 {
			font-size: 3rem;
			color: $primary-color;

			.step-text {
				line-height: 4rem;
				padding-left: 7rem;
			}
		}
	}
}
@media (min-width: 770px) {
	.step01-content {
		width: 50%;
	}
	.step01-image {
		width: 50%;
	}
}

.step01-image {
	text-align: center;
	@media (max-width: 769px) {
		img {
			padding: 0 15% !important;
		}
	}
}
.step02-image {
	img {
		max-width: 100% !important;
		padding: 0 !important;
		@media (max-width: 500px) {
			padding: 10% !important;
		}
	}
}

.step02 {
	@media (max-width: 769px) {
		flex-direction: column-reverse !important;
	}
	.title {
		.step-title {
			padding: 0 10%;
			/*line-height: 1;*/
		}
	}
	.step02-image {
		@media (max-width: 769px) {
			img {
				padding: 1% 15% !important;
			}
		}
	}
}
.step03 {
	padding: 50px 5%;
	/*height: 100vh;*/
	.content {
		.description {
			padding: 0 10%;
		}
	}
	.step03-image {
		width: 50%;
		img {
			width: 100%;
		}
		@media (max-width: 769px) {
			width: 100% !important;
			img {
				padding: 5% 15%;
				width: 100% !important;
			}
		}
	}
}
