.payment-form-container {
    display: flex;
    margin: 0 auto;

    .section-header {
        // font-family: var(--font-stack-heading-serif);
        font-weight: 700;
        font-size: 3.2rem;
        line-height: 1.25;
        letter-spacing: -.016rem;
        max-width: 36em;
        margin: 3.2rem 0;
    }

    .section-header-title {
        margin: 1rem 0;
        font-size: 18px;
        font-weight: 600;
    }

    .secure-connection-text {
        margin: 0;
        font-size: 14px;
        font-weight: 300;
        color: #d1d7db !important;
    }

    .section-header-sub-title {
        margin: 1rem 0 0 0;
        font-size: 16px;
        font-weight: 300;
    }

    .font-16 {
        font-size: 16px !important;
    }

    .section-header2 {
        font-size: 18px;
        margin: 0;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: -.016rem;
    }

    .select-parent {
        padding: 13px;
        width: 50%;
        cursor: pointer;
        border: 1px solid #d1d7db;
        border-radius: 10px;

        margin-bottom: 2.2rem;
        appearance: none;
        background-image: url('../assets/img/visa-cards/arrow-down.svg');
        background-repeat: no-repeat;
        background-position: right 15px center;
    }

    .right-section {
        width: 60%;
        height: 100%;
        background-color: white;

        @media (max-width: 800px) {
            width: 100%;
        }

        .right-content {
            margin: 0 auto;
            padding: 0 8rem;
            background: #fff;


            @media (max-width: 800px) {
                padding: 2rem;
            }

            .payment {
                border: 1px solid #e4e5e5;
                border-radius: 20px;
                padding: 5px;

                .mb-0 {
                    margin-bottom: 0;
                }

                .payment-card {
                    width: 100%;
                    // background: #e9f3ff;
                    border-radius: 15px;
                    // border: 1px solid #2d2f31;
                    cursor: pointer;
                    margin: 0 1rem 1rem 0;

                    .payment-card--header {
                        display: flex;
                        align-items: center;
                        padding: 1rem;
                        justify-content: space-between;
                        border-radius: 15px !important;
                        background: #e9f3ff;

                        .radio {
                            margin: 0 10px;
                        }
                    }

                    .card-default {
                        width: 30px;
                        margin: 0 1rem 0 0;
                    }

                    .card-default-visa {
                        width: 45px;
                        margin: 0px 5px 0 0;
                        background: white;
                        border-radius: 3px;
                    }

                    .card-body {
                        padding: 1.2rem 2.4rem;
                        // border-top: 1px solid #e4e5e5;
                        background: white;
                        cursor: auto;

                        .input {
                            width: 50%;
                            padding: 1rem;
                            margin: 0 0 1rem 0;
                            border-radius: 10px;
                            border: 1px solid #d1d7db;
                        }
                    }
                }
            }

            .course-details {
                margin: 3.2rem 0 0 0;

                .course-details--course-div {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
            }
        }
    }

    .left-section {
        width: 40%;
        height: 100%;
        background-color: #e9f3ff;
        height: calc(100vh - 70px);

        @media (max-width: 800px) {
            height: 100%;
        }

        @media (max-width: 800px) {
            width: 100%;
        }

        .left-content {
            margin: 0 auto;
            padding: 0 8rem;

            @media (max-width: 800px) {
                padding: 8rem;
            }

            .section-header {
                // font-family: var(--font-stack-heading-serif);
                font-weight: 700;
                font-size: 3.2rem;
                line-height: 1.25;
                letter-spacing: -.016rem;
                max-width: 36em;
                margin-top: 10.4rem;

                @media (max-width: 800px) {
                    margin-top: 3.4rem;
                }
            }

            .pricing-body {
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                    margin: 0;
                }

                .strike-through {
                    text-decoration: line-through;
                }
            }

            .seperator {
                border-bottom: 1px solid #d1d7dc;
                margin: 1.2rem 0;
            }
        }
    }
}

.payment-card-element {
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 8px;
}

.CardElement {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 16px;
}

.pay-now-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 10px !important;
    margin-top: 1rem;
    cursor: pointer;
}

.pay-now-button:hover {
    background-color: #0056b3;
}

.validate-button {
    background-color: #38d430;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    margin-top: 1rem;
    cursor: pointer;
    border-radius: 10px !important;
}

.validate-button:hover {
    background-color: #4aad4e;
}

.payment-form-container {
    display: flex;
    justify-content: center;
    // align-items: center;

    @media (max-width: 800px) {
        height: 100%;
        flex-direction: column;
    }
}

.payment-form-container .payment-card-element {
    width: 100%;
}

.payment-form-container .payment-card-element div {
    margin-bottom: 15px;
}

.payment-form-container label {
    display: block;
    margin-bottom: 5px;
}

.payment-form-container .card-details-container {
    display: flex;
    flex-direction: column;
}

.payment-form-container .card-details-container>div {
    margin-bottom: 10px;
}

.payment-form-container button {
    width: 100%;
    border-radius: 0;
}