@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";
$primary-color: #0384e5;

.Work-container {
    width: 100%;

    @media (max-width: 480px) {
        display: inline;
    }

    .slide {
        transform: scale(0.9);
        transition: transform 500ms;

        img {
            width: 100%;
            margin: 10px auto;
            max-width: 400px;
        }
    }

    .activeSlide {
        opacity: 1;
        transition: all 0.3s ease-out;
        transform: scale(1.05);

        @media (max-width: 768px) {
            transform: scale(0.9);
        }
    }

    .slider-image {
        cursor: pointer;
    }
}

.section-work {
    height: 50rem;

    @media (max-width: 768px) {
        height: 100%;
    }

    .work-container {
        position: absolute;
        top: 20rem;
        left: 0;
        right: 0;

        @media (max-width: 300px) {
            top: 10rem !important;
        }

        @media (max-width: 390px) {
            top: 20% !important;
        }

        @media (max-width: 500px) {
            top: 30% !important;
        }

        @media (max-width: 768px) {
            top: 10%;
        }
    }

    .work-bg {
        width: 100%;
        height: 100%;

        @media (max-width: 500px) {
            height: 50vh;
        }
    }
}

.wc-title {
    position: absolute;
    color: $primary-color;
    top: 0;
    width: 100%;
    height: 35%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        height: 10%;
    }

    @media (max-width: 500px) {
        height: 35%;
        font-size: 2.5rem !important;
    }

}