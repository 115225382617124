.logo {
	margin: 0 0 0 0.45em;

	a {
		color: var(--white);
		font-size: 4rem;
	}

	img.header-logo {
		width: 120px;
		height: unset;
		padding: 10px;
		object-fit: cover;
	}
}

.logo,
.navigation {
	list-style: none;

	a {
		text-decoration: none;
	}
}

header {
	position: fixed;
	top: 0;
	z-index: 109;
	background: var(--gradient-blue); // todo: change header color
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	box-shadow: 1px 1px 1px 1px rgba(116, 110, 110, 0.199);
}

.header-inner {
	width: 100%;
	display: flex;
	height: 70px;
	align-items: center;
	justify-content: space-between;
}

.hamburger-btn {
	font-size: 3.5rem;
	position: absolute;
	top: 0.6rem;
	bottom: 0;
	width: 40px;
	height: 35px;
	right: 0.6px;
	outline: none;
	background: transparent;
	color: white;
	border: none;
}

.addTransiton {
	transform: translateX(170px);
	transition: transform 0.5s ease-in-out;
	display: none;
}

.removeTransition {
	transform: translateX(20px);
	transition: transform 0.5s ease-in-out;
	display: none;
}

#sidebar-list {
	background: var(--gradient-blue);
	height: 100vh;
	width: 100vw;
	position: absolute;
	z-index: 2000;
	right: 0;
	top: 0;
	margin-top: 6rem;
}

.nav-big {
	list-style: none;
	display: flex;
	justify-content: space-between;
	width: 55%;

	@media (max-width: 1300px) {
		width: 80%;
	}

	font-weight: bold;

	a {
		// display: block;
		// font-size: 1.5rem;
		// padding: 1rem;
		// color: white;
		// opacity: 0.7;
		// position: relative;
		// font-weight: 100;
		display: flex;
		font-size: 1.5rem;
		padding: 1rem;
		color: white;
		opacity: 0.7;
		position: relative;
		font-weight: 100;
		align-items: center;
		justify-content: center;
		text-align: center;

		&:after {
			background: none repeat scroll 0 0 transparent;
			bottom: 0;
			content: "";
			display: block;
			height: 2px;
			left: 50%;
			position: absolute;
			background: #fff;
			transition: width 0.4s ease 0s, left 0.4s ease 0s;
			width: 0;
		}

		&:hover {
			color: var(--white);
			opacity: 1;

			&::after {
				padding-right: 1.9rem;
				width: 100%;
				left: 0%;
			}
		}
	}
}

.nav-big-link {
	cursor: pointer;
	color: white;
	text-decoration: none !important;
}

.nav-small {
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: space-around;
	margin: auto;
	height: 40%;
	margin-top: 50px;
	width: 80%;
}

.nav-small-link {
	cursor: pointer;
	color: whitesmoke;
	font-size: 2rem;
	text-decoration: none !important;
	padding-bottom: 5px;
}

.language-selector {
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-self: center;
	margin-right: 10px;
	margin-left: 3rem;

	@media (max-width: 500px) {
		margin-right: 50px;
	}

	@media (max-width: 768px) {
		margin-right: 50px;
	}

	@media (max-width: 1200px) {
		margin-right: 50px;
	}

	button.language-button {
		background: transparent;
		border: none;
		color: whitesmoke;
		font-size: 1.5rem;
		padding: 0;
		margin: 0;
		cursor: pointer;

		&:hover {
			color: var(--white);
		}

		&.selected {
			border-bottom: 1px solid white;
		}
	}
}

.color-white {
	color: white !important;
	opacity: 1 !important;
}