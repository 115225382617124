@font-face {
	font-family: 'Lato';
	/* Regular weight = 400 */
	src: url('./assets/fonts/Lato/Lato-Regular.ttf');
}

@font-face {
	font-family: 'Lato';
	font-weight: 500;
	src: url('./assets/fonts/Lato/Lato-Medium.ttf');
}
@font-face {
	font-family: 'Lato';
	font-weight: 700;
	src: url('./assets/fonts/Lato/Lato-Bold.ttf');
}
@font-face {
	font-family: 'Lato';
	font-weight: 900;
	src: url('./assets/fonts/Lato/Lato-Heavy.ttf');
}

@font-face {
	font-family: 'Gotham-Light-Italic';
	font-weight: 900;
	src: url('./assets/fonts/metropolis/Metropolis-ThinItalic.otf');
}

@font-face {
	font-family: 'Gotham-Light';
	font-weight: 300;
	/* src: url('./assets/fonts/Gotham/GothamLight.ttf'); */
	src: url('./assets/fonts/metropolis/Metropolis-Light.otf');
}

@font-face {
	font-family: 'Gotham';
	font-weight: 800;
	src: url('./assets/fonts/metropolis/Metropolis-Medium.otf');
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: Lato;
}
/* 1rem = 10px */
html {
	font-size: 10px;
}

:root {
	--darkBlue: #0b4b79;
	--white: #ffffff;
	--gray: gray;
	--text-gray: #818181;
	--demo-gradient-blue1: #1c6e96;
	--demo-gradient-blue2: #237cb3;
	--watch-gradient-orange1: #f69439;
	--watch-gradient-orange2: #f27e84;
	--cards-gray: #ededed;
	--pricing-gradient-blue1: #1f7ab2;
	--pricing-gradient-blue2: #1b6d98;
	--gradient-blue: linear-gradient(to right, #0384e5, #10b0df) !important;
	/* --pricing-off-white: #DDDCD9; */
	/* --pricing-off-white2: #F9F8F5; */
	--orange: orange;
	--lightBlue: lightblue;
	--lighterBlue: #cde8f0;
	--bold: montserratBold;
	--h1: 5rem;
	--dark-grey: #707070;
}

*:focus {
	outline: none;
}
