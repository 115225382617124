.team-section-container {
	//background-image: linear-gradient(to right, rgba(39, 108, 166, 0.8392156863), rgba(67, 181, 186, 0.8196078431));
	background: #0583e5;

	@media (max-width: 480px) {
		height: 100vh;
	}

	.team-section {
		position: relative;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		align-content: space-around;
		flex-wrap: wrap;

		.slick-slider {
			display: grid !important;
			padding: 0% 20%;
			@media (max-width: 500px) {
				padding: 0;
				width: 100% !important;
				display: inline-grid !important;
			}
		}
		img.teamImage {
			width: 100%;
			position: absolute;
			height: 100%;
			object-fit: cover;
			top: 0;
			right: 0;
			left: 0;
			z-index: -1;
		}
		@media (max-width: 500px) {
			position: relative;
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			height: 100% !important;
			flex-wrap: wrap;
			width: 100%;
			align-items: center;
			align-content: space-around;
			align-content: flex-start;
		}
	}

	.team-title {
		font-weight: bold;
		font-size: 5rem;
		color: var(--white);
		margin-top: 3%;

		@media (max-width: 480px) {
			font-size: 2rem;
		}
		@media (max-width: 768px) {
			font-size: 3rem;
			margin-top: 5%;
			margin-bottom: 10%;
		}
	}

	.team-sub-title {
		color: white;
		font-size: 3rem;
		margin-top: 2%;
		font-family: "Gotham-light";
		@media (max-width: 768px) {
			margin-top: 3%;
		}
		@media (max-width: 480px) {
			margin-top: 10%;
		}
	}

	.team-description {
		font-size: 2.5rem !important;
		margin-top: 35px;
		color: var(--white);
		font-family: "Gotham-light";
		line-height: 4.5rem;
		padding: 0 15%;
		-webkit-font-smoothing: auto;

		@media (max-width: 1400px) {
			font-size: 3rem;
			//padding: 0 3rem;
			display: block;
			//line-height: 3.5rem;
		}

		@media (max-width: 480px) {
			font-size: 2.5rem !important;
			padding: 0 3rem;
			margin-top: 0 !important;
		}
		@media (max-width: 768px) {
			font-size: 3rem;
			/*margin-top: 25%;*/
			padding: 0 5%;
			display: block;
			//line-height: 3rem;
		}
	}

	.team-partners {
		img {
			width: 100%;
			padding: 0 5%;

			@media (max-width: 1400px) {
			}

			@media (max-width: 1200px) {
			}

			@media (max-width: 480px) {
				padding: 0 5%;
			}
			@media (max-width: 768px) {
				padding: 0 5%;
				margin-bottom: 5%;
			}
		}
	}
}
.slide {
	transition: transform 500ms;

	.slider-image-team {
		cursor: pointer;
		width: 80%;
		margin: auto;
		@media (max-width: 786px) {
			width: 100%;
		}
		@media (max-width: 500px) {
			// width: 60%;
		}
		@media (max-width: 320px) {
			width: 100%;
		}
	}
}

.team-slider {
	.slick-list {
		margin-bottom: 10px;
		.slick-track {
			.slick-slide {
				height: 120px;
				display: flex;
				flex-wrap: nowrap;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
		}
	}
}

@media (max-width: 768px) {
	.team-slider {
		.slick-list {
			margin-bottom: 20px;
			.slick-track {
				.slick-slide {
					height: 120px;
					display: flex;
					flex-wrap: nowrap;
					flex-direction: column;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}
}

@media (max-width: 500px) {
	.team-slider {
		.slick-list {
			margin-bottom: 0px !important;
			.slick-track {
				.slick-slide {
					height: 120px;
					display: flex;
					flex-wrap: nowrap;
					flex-direction: column;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}

	.team-section-title {
		// margin-top: 5rem;
	}
}

.images-container {
	column-count: 3;
	column-gap: 10px;
	width: 100%;
	padding: 0 15%;
	opacity: 0.75;
	@media (max-width: 500px) {
		padding: 0;
	}
	@media (max-width: 769px) {
		column-count: 2;
	}
	div {
		max-height: 120px;
		height: 120px;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: center;
		align-items: center;

		@media (max-width: 405px) {
			max-height: 55px !important;
		}
	}
	img {
		margin: 20px 0;
		max-width: 75%;
	}
}

.text-read-more {
	font-size: 1.5rem;
	text-decoration: underline;
	color: white;
	font-family: "Lato";
	font-weight: 100;
}

.read-more-btn {
	display: inline-block;
	margin-top: 25px;

	@media (max-width: 480px) {
		margin-right: 0;
		width: 100%;
	}
	@media (max-width: 768px) {
		font-size: 2rem;
	}
	padding: 1.5rem 8rem;
	border-radius: 1rem;
	background-color: white;
	color: #0584e5;
	top: 0;
	border: 0;
	width: fit-content;
	margin: 25px auto;
	font-size: 18px;
	transition: all 0.3s ease-out;
	cursor: pointer;
	box-shadow: 3px 4px 20px -8px black;
	text-decoration: unset;

	&:hover {
		background: #fff;
		color: #0584e5;
		box-shadow: 0 0.4rem 0.4rem rgba(83, 100, 255, 0.32);
	}
}
