html, body {
    overflow-x:hidden;
    width:100%;
   /* You may also want to try adding:*/
    margin: 0;
    padding: 0;
}

.dflex {
	display: flex;
	flex-direction: column;
}
.styles-module_chatHeader__23UA6 .styles-module_avatar__17BL2 img{
    height: 28px !important;
    width: 60px !important;
    border-radius: 0 !important;
    border: none !important;
}
.styles-module_chatBody__3SG9H{
    padding-top: 70px !important;
}
.COMMON_TITLE_BOLD{
    font-family: "Gotham" !important;
    font-size: 4rem !important;
    font-weight: bold !important;
    letter-spacing: 1 !important;
}