$primary-color: #0384e5;
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

.main-flex-container {
	height: 75vh;
	margin-top: 5rem;
	display: flex;
	@media (max-width: 1000px) {
		height: 100%;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		background: var(--white);
		position: relative;
		flex-direction: row;
		flex-wrap: wrap;
		align-content: space-between;
	}

	justify-content: space-between;
	align-items: center;
	background: var(--white);
	position: relative;
	.main-left {
		width: 60%;
		padding-inline-start: 10%;
		@media (max-width: 1000px) {
			width: 100% !important;
			padding: 1rem 2rem;
			text-align: center;
			margin-top: 10vh;
		}
		@media (max-width: 500px) {
			width: 100% !important;
			padding: 1rem 2rem;
			text-align: center;
		}
		@media (max-width: 800px) {
			width: 40%;
		}
		@media (max-width: 768px) {
			padding: 0% 5%;
		}
		h1 {
			font-weight: bold;
			font-size: 5rem;
			color: #0384e5;
			font-family: "Gotham";
			line-height: 1;
			@media (max-width: 1000px) {
				font-size: 5rem;
				text-align: center;
				margin-top: 3vh;
			}
			@media (max-width: 500px) {
				font-size: 4rem;
				text-align: center;
				margin-top: 10%;
			}
			@media (max-width: 768px) {
				font-size: 4rem;
			}
			color: $primary-color;
		}
		h3 {
			color: #757575;
			margin-top: 3rem;
			margin-bottom: 3rem;
			font-family: Gotham-light;
			line-height: 3rem;
			font-size: 3rem;
			@media (max-width: 1100px) {
				width: 75%;
			}
			@media (max-width: 1000px) {
				font-size: 2.3rem;
				text-align: center;
				margin-top: 5rem;
				width: 100%;
			}
			@media (max-width: 500px) {
				font-size: 2.3rem;
				text-align: center;
				margin-top: 5rem;
				width: 100%;
			}
			@media (max-width: 768px) {
				padding: 0;
			}
			font-weight: normal;
			opacity: 0.8;
		}

		.main-demo {
			display: inline-block;
			margin-right: 3rem;
			@media (max-width: 480px) {
				margin-right: 1rem;
			}
			background: $primary-color;
			min-width: 179px;
			color: var(--white);
			text-decoration: none;
			font-size: 2rem;
			line-height: 3.8rem;
			border-radius: 1rem;
			-webkit-transition: all 1s;
			transition: all 1s;
			padding: 1rem 2rem;
			text-align: center;
			cursor: pointer;
			&:hover {
				background: $primary-color;
			}
		}

		.main-watch {
			display: inline-block;
			margin-right: 3rem;
			@media (max-width: 480px) {
				margin-right: 0;
				padding-inline-start: 1rem;
			}
			background-clip: text;
			background-color: #0584e5;
			-webkit-text-fill-color: transparent;
			font-size: 2rem;
			line-height: 3.8rem;
			border-radius: 1rem;
			-webkit-transition: all 0.3s;
			transition: all 0.3s;
			padding: 1rem 0;
			text-align: center;
			position: relative;

			text-decoration-line: underline !important;
			text-decoration-color: #0584e5 !important;
			text-underline-offset: 8px;

			cursor: pointer;
			&:before {
				content: "";
				//border-bottom: 1px solid $primary-color;
				padding: 0 6.3rem;
				position: absolute;
				bottom: 1.5rem;
				left: 0;
				right: 0;
				margin: 0 auto;
				width: 0;
				-webkit-transition: all 0.3s;
				@media (max-width: 480px) {
					display: none;
				}
			}
			&:hover {
				transform: scale(1.1);
			}
			// &:hover {
			// 	background: var(--darkBlue);
			// 	color: var(--orange);
			// 	box-shadow: 0 0.4rem 0.4rem rgba(83, 100, 255, 0.32);
			// 	&:before {
			// 		border-bottom: 2px solid var(--orange);
			// 	}
			// }
		}
	}
	.main-container-right {
		width: 100%;
	}
	.main-right {
		text-align: right;
		position: absolute;
		top: 1vh;
		right: 0;
		@media (max-width: 1000px) {
			display: block;
			position: unset;
		}
		// @-webkit-keyframes action {
		// 	0% { transform: translateY(0); }
		// 	100% { transform: translateY(-10px); }
		// }

		@media (max-width: 1450px) {
			@keyframes action01 {
				0% {
					transform: translate(-120%, 15%);
					-webkit-transform: translate(-120%, 15%);
					-moz-transform: translate(-120%, 15%);
					-o-transform: translate(-120%, 15%);
					-ms-transform: translate(-120%, 15%);
				}
				100% {
					transform: translate(-120%, 10%);
					-webkit-transform: translate(-120%, 10%);
					-moz-transform: translate(-120%, 10%);
					-o-transform: translate(-120%, 10%);
					-ms-transform: translate(-120%, 10%);
				}
			}
			@keyframes action02 {
				0% {
					transform: translate(-115%, 150%);
					-webkit-transform: translate(-115%, 150%);
					-moz-transform: translate(-115%, 150%);
					-o-transform: translate(-115%, 150%);
					-ms-transform: translate(-115%, 150%);
				}
				100% {
					transform: translate(-115%, 155%);
					-webkit-transform: translate(-115%, 155%);
					-moz-transform: translate(-115%, 155%);
					-o-transform: translate(-115%, 155%);
					-ms-transform: translate(-115%, 155%);
				}
			}
			@keyframes action03 {
				0% {
					transform: translate(-235%, 120%);
					-webkit-transform: translate(-235%, 120%);
					-moz-transform: translate(-235%, 120%);
					-o-transform: translate(-235%, 120%);
					-ms-transform: translate(-235%, 120%);
				}
				100% {
					transform: translate(-235%, 125%);
					-webkit-transform: translate(-235%, 125%);
					-moz-transform: translate(-235%, 125%);
					-o-transform: translate(-235%, 125%);
					-ms-transform: translate(-235%, 125%);
				}
			}
			@keyframes action04 {
				0% {
					transform: translate(-220%, 250%);
					-webkit-transform: translate(-220%, 250%);
					-moz-transform: translate(-220%, 250%);
					-o-transform: translate(-220%, 250%);
					-ms-transform: translate(-220%, 250%);
				}
				100% {
					transform: translate(-220%, 245%);
					-webkit-transform: translate(-220%, 245%);
					-moz-transform: translate(-220%, 245%);
					-o-transform: translate(-220%, 245%);
					-moz-transform: translate(-220%, 245%);
				}
			}
		}

		@media (max-width: 1200px) {
			@keyframes action01 {
				0% {
					transform: translate(-120%, 60%);
					-webkit-transform: translate(-120%, 60%);
					-moz-transform: translate(-120%, 60%);
					-o-transform: translate(-120%, 60%);
					-ms-transform: translate(-120%, 60%);
				}
				100% {
					transform: translate(-120%, 55%);
					-webkit-transform: translate(-120%, 55%);
					-moz-transform: translate(-120%, 55%);
					-o-transform: translate(-120%, 55%);
					-ms-transform: translate(-120%, 55%);
				}
			}
			@keyframes action02 {
				0% {
					transform: translate(-115%, 200%);
					-webkit-transform: translate(-115%, 200%);
					-moz-transform: translate(-115%, 200%);
					-o-transform: translate(-115%, 200%);
					-ms-transform: translate(-115%, 200%);
				}
				100% {
					transform: translate(-115%, 195%);
					-webkit-transform: translate(-115%, 195%);
					-moz-transform: translate(-115%, 195%);
					-o-transform: translate(-115%, 195%);
					-ms-transform: translate(-115%, 195%);
				}
			}
			@keyframes action03 {
				0% {
					transform: translate(-235%, 170%);
					-webkit-transform: translate(-235%, 170%);
					-moz-transform: translate(-235%, 170%);
					-o-transform: translate(-235%, 170%);
					-ms-transform: translate(-235%, 170%);
				}
				100% {
					transform: translate(-235%, 165%);
					-webkit-transform: translate(-235%, 165%);
					-moz-transform: translate(-235%, 165%);
					-o-transform: translate(-235%, 165%);
					-ms-transform: translate(-235%, 165%);
				}
			}
			@keyframes action04 {
				0% {
					transform: translate(-220%, 300%);
					-webkit-transform: translate(-220%, 300%);
					-moz-transform: translate(-220%, 300%);
					-o-transform: translate(-220%, 300%);
					-ms-transform: translate(-220%, 300%);
				}
				100% {
					transform: translate(-220%, 295%);
					-webkit-transform: translate(-220%, 295%);
					-moz-transform: translate(-220%, 295%);
					-o-transform: translate(-220%, 295%);
					-ms-transform: translate(-220%, 295%);
				}
			}
		}

		@media (max-width: 1100px) {
			@keyframes action01 {
				0% {
					transform: translate(-120%, 50%);
					-webkit-transform: translate(-120%, 50%);
					-moz-transform: translate(-120%, 50%);
					-o-transform: translate(-120%, 50%);
					-ms-transform: translate(-120%, 50%);
				}
				100% {
					transform: translate(-120%, 45%);
					-webkit-transform: translate(-120%, 45%);
					-moz-transform: translate(-120%, 45%);
					-o-transform: translate(-120%, 45%);
					-ms-transform: translate(-120%, 45%);
				}
			}
			@keyframes action02 {
				0% {
					transform: translate(-115%, 200%);
					-webkit-transform: translate(-115%, 200%);
					-moz-transform: translate(-115%, 200%);
					-o-transform: translate(-115%, 200%);
					-ms-transform: translate(-115%, 200%);
				}
				100% {
					transform: translate(-115%, 195%);
					-webkit-transform: translate(-115%, 195%);
					-moz-transform: translate(-115%, 195%);
					-o-transform: translate(-115%, 195%);
					-ms-transform: translate(-115%, 195%);
				}
			}
			@keyframes action03 {
				0% {
					transform: translate(-235%, 180%);
					-webkit-transform: translate(-235%, 180%);
					-moz-transform: translate(-235%, 180%);
					-o-transform: translate(-235%, 180%);
					-ms-transform: translate(-235%, 180%);
				}
				100% {
					transform: translate(-235%, 175%);
					-webkit-transform: translate(-235%, 175%);
					-moz-transform: translate(-235%, 175%);
					-o-transform: translate(-235%, 175%);
					-ms-transform: translate(-235%, 175%);
				}
			}
			@keyframes action04 {
				0% {
					transform: translate(-220%, 325%);
					-webkit-transform: translate(-220%, 325%);
					-moz-transform: translate(-220%, 325%);
					-o-transform: translate(-220%, 325%);
					-ms-transform: translate(-220%, 325%);
				}
				100% {
					transform: translate(-220%, 320%);
					-webkit-transform: translate(-220%, 320%);
					-moz-transform: translate(-220%, 320%);
					-o-transform: translate(-220%, 320%);
					-ms-transform: translate(-220%, 320%);
				}
			}
		}
		@media (max-width: 1000px) {
			@keyframes action01 {
				0% {
					transform: translate(-120%, 90%);
					-webkit-transform: translate(-120%, 90%);
					-moz-transform: translate(-120%, 90%);
					-o-transform: translate(-120%, 90%);
					-ms-transform: translate(-120%, 90%);
				}
				100% {
					transform: translate(-120%, 85%);
					-webkit-transform: translate(-120%, 85%);
					-moz-transform: translate(-120%, 85%);
					-o-transform: translate(-120%, 85%);
					-ms-transform: translate(-120%, 85%);
				}
			}
			@keyframes action02 {
				0% {
					transform: translate(-115%, 230%);
					-webkit-transform: translate(-115%, 230%);
					-moz-transform: translate(-115%, 230%);
					-o-transform: translate(-115%, 230%);
					-ms-transform: translate(-115%, 230%);
				}
				100% {
					transform: translate(-115%, 225%);
					-webkit-transform: translate(-115%, 225%);
					-moz-transform: translate(-115%, 225%);
					-o-transform: translate(-115%, 225%);
					-ms-transform: translate(-115%, 225%);
				}
			}
			@keyframes action03 {
				0% {
					transform: translate(-235%, 200%);
					-webkit-transform: translate(-235%, 200%);
					-moz-transform: translate(-235%, 200%);
					-o-transform: translate(-235%, 200%);
					-ms-transform: translate(-235%, 200%);
				}
				100% {
					transform: translate(-235%, 195%);
					-webkit-transform: translate(-235%, 195%);
					-moz-transform: translate(-235%, 195%);
					-o-transform: translate(-235%, 195%);
					-ms-transform: translate(-235%, 195%);
				}
			}
			@keyframes action04 {
				0% {
					transform: translate(-220%, 330%);
					-webkit-transform: translate(-220%, 330%);
					-moz-transform: translate(-220%, 330%);
					-o-transform: translate(-220%, 330%);
					-ms-transform: translate(-220%, 330%);
				}
				100% {
					transform: translate(-220%, 325%);
					-webkit-transform: translate(-220%, 325%);
					-moz-transform: translate(-220%, 325%);
					-o-transform: translate(-220%, 325%);
					-ms-transform: translate(-220%, 325%);
				}
			}
		}

		@media (max-width: 900px) {
			@keyframes action01 {
				0% {
					transform: translate(-120%, 100%);
					-webkit-transform: translate(-120%, 100%);
					-moz-transform: translate(-120%, 100%);
					-o-transform: translate(-120%, 100%);
					-ms-transform: translate(-120%, 100%);
				}
				100% {
					transform: translate(-120%, 95%);
					-webkit-transform: translate(-120%, 95%);
					-moz-transform: translate(-120%, 95%);
					-o-transform: translate(-120%, 95%);
					-ms-transform: translate(-120%, 95%);
				}
			}
			@keyframes action02 {
				0% {
					transform: translate(-115%, 280%);
					-webkit-transform: translate(-115%, 280%);
					-moz-transform: translate(-115%, 280%);
					-o-transform: translate(-115%, 280%);
					-ms-transform: translate(-115%, 280%);
				}
				100% {
					transform: translate(-115%, 275%);
					-webkit-transform: translate(-115%, 275%);
					-moz-transform: translate(-115%, 275%);
					-o-transform: translate(-115%, 275%);
					-ms-transform: translate(-115%, 275%);
				}
			}
			@keyframes action03 {
				0% {
					transform: translate(-235%, 305%);
					-webkit-transform: translate(-235%, 305%);
					-moz-transform: translate(-235%, 305%);
					-o-transform: translate(-235%, 305%);
					-ms-transform: translate(-235%, 305%);
				}
				100% {
					transform: translate(-235%, 300%);
					-webkit-transform: translate(-235%, 300%);
					-moz-transform: translate(-235%, 300%);
					-o-transform: translate(-235%, 300%);
					-ms-transform: translate(-235%, 300%);
				}
			}
			@keyframes action04 {
				0% {
					transform: translate(-220%, 470%);
					-webkit-transform: translate(-220%, 470%);
					-moz-transform: translate(-220%, 470%);
					-o-transform: translate(-220%, 470%);
					-ms-transform: translate(-220%, 470%);
				}
				100% {
					transform: translate(-220%, 465%);
					-webkit-transform: translate(-220%, 465%);
					-moz-transform: translate(-220%, 465%);
					-o-transform: translate(-220%, 465%);
					-ms-transform: translate(-220%, 465%);
				}
			}
		}
		@media (max-width: 800px) {
			@keyframes action01 {
				0% {
					transform: translate(-120%, 130%);
					-webkit-transform: translate(-120%, 130%);
					-moz-transform: translate(-120%, 130%);
					-o-transform: translate(-120%, 130%);
					-ms-transform: translate(-120%, 130%);
				}
				100% {
					transform: translate(-120%, 125%);
					-webkit-transform: translate(-120%, 125%);
					-moz-transform: translate(-120%, 125%);
					-o-transform: translate(-120%, 125%);
					-ms-transform: translate(-120%, 125%);
				}
			}
			@keyframes action02 {
				0% {
					transform: translate(-115%, 305%);
					-webkit-transform: translate(-115%, 305%);
					-moz-transform: translate(-115%, 305%);
					-o-transform: translate(-115%, 305%);
					-ms-transform: translate(-115%, 305%);
				}
				100% {
					transform: translate(-115%, 300%);
					-webkit-transform: translate(-115%, 300%);
					-moz-transform: translate(-115%, 300%);
					-o-transform: translate(-115%, 300%);
					-ms-transform: translate(-115%, 300%);
				}
			}
			@keyframes action03 {
				0% {
					transform: translate(-235%, 330%);
					-webkit-transform: translate(-235%, 330%);
					-moz-transform: translate(-235%, 330%);
					-o-transform: translate(-235%, 330%);
					-ms-transform: translate(-235%, 330%);
				}
				100% {
					transform: translate(-235%, 325%);
					-webkit-transform: translate(-235%, 325%);
					-moz-transform: translate(-235%, 325%);
					-o-transform: translate(-235%, 325%);
					-ms-transform: translate(-235%, 325%);
				}
			}
			@keyframes action04 {
				0% {
					transform: translate(-220%, 510%);
					-webkit-transform: translate(-220%, 510%);
					-moz-transform: translate(-220%, 510%);
					-o-transform: translate(-220%, 510%);
					-ms-transform: translate(-220%, 510%);
				}
				100% {
					transform: translate(-220%, 505%);
					-webkit-transform: translate(-220%, 505%);
					-moz-transform: translate(-220%, 505%);
					-o-transform: translate(-220%, 505%);
					-ms-transform: translate(-220%, 505%);
				}
			}
		}
		@media (max-width: 600px) {
			@keyframes action01 {
				0% {
					transform: translate(-120%, 200%);
					-webkit-transform: translate(-120%, 200%);
					-moz-transform: translate(-120%, 200%);
					-o-transform: translate(-120%, 200%);
					-ms-transform: translate(-120%, 200%);
				}
				100% {
					transform: translate(-120%, 195%);
					-webkit-transform: translate(-120%, 195%);
					-moz-transform: translate(-120%, 195%);
					-o-transform: translate(-120%, 195%);
					-ms-transform: translate(-120%, 195%);
				}
			}
			@keyframes action02 {
				0% {
					transform: translate(-115%, 405%);
					-webkit-transform: translate(-115%, 405%);
					-moz-transform: translate(-115%, 405%);
					-o-transform: translate(-115%, 405%);
					-ms-transform: translate(-115%, 405%);
				}
				100% {
					transform: translate(-115%, 400%);
					-webkit-transform: translate(-115%, 400%);
					-moz-transform: translate(-115%, 400%);
					-o-transform: translate(-115%, 400%);
					-ms-transform: translate(-115%, 400%);
				}
			}
			@keyframes action03 {
				0% {
					transform: translate(-235%, 450%);
					-webkit-transform: translate(-235%, 450%);
					-moz-transform: translate(-235%, 450%);
					-o-transform: translate(-235%, 450%);
					-ms-transform: translate(-235%, 450%);
				}
				100% {
					transform: translate(-235%, 445%);
					-webkit-transform: translate(-235%, 445%);
					-moz-transform: translate(-235%, 445%);
					-o-transform: translate(-235%, 445%);
					-ms-transform: translate(-235%, 445%);
				}
			}
			@keyframes action04 {
				0% {
					transform: translate(-220%, 650%);
					-webkit-transform: translate(-220%, 650%);
					-moz-transform: translate(-220%, 650%);
					-o-transform: translate(-220%, 650%);
					-ms-transform: translate(-220%, 650%);
				}
				100% {
					transform: translate(-220%, 645%);
					-webkit-transform: translate(-220%, 645%);
					-moz-transform: translate(-220%, 645%);
					-o-transform: translate(-220%, 645%);
					-ms-transform: translate(-220%, 645%);
				}
			}
		}
		.main-img-01 {
			width: 25vw;
			//max-width: 390px;
			position: absolute;
			top: 0px;
			left: 0;
			transform: translate(-120%, 15%);
			-webkit-transform: translate(-120%, 15%);
			-moz-transform: translate(-120%, 15%);
			-o-transform: translate(-120%, 15%);
			-ms-transform: translate(-120%, 15%);
			z-index: 1;
			animation: action01 2s ease-in-out infinite alternate;
			-webkit-animation: action01 2s ease-in-out infinite alternate;
			-moz-animation: action01 2s ease-in-out infinite alternate;
			-o-animation: action01 2s ease-in-out infinite alternate;
			-ms-animation: action01 2s ease-in-out infinite alternate;
			@media (max-width: 1300px) {
				max-width: unset;
			}
			@media (max-width: 768px) {
				max-width: unset;
			}
		}

		.main-img-02 {
			width: 25vw;
			max-width: 390px;
			position: absolute;
			top: 0px;
			left: 0;
			transform: translate(-115%, 155%);
			-webkit-transform: translate(-115%, 155%);
			-moz-transform: translate(-115%, 155%);
			-ms-transform: translate(-115%, 155%);
			-o-transform: translate(-115%, 155%);
			z-index: 1;
			animation: action02 2s ease-in-out infinite alternate;
			-webkit-animation: action02 2s ease-in-out infinite alternate;
			-moz-animation: action02 2s ease-in-out infinite alternate;
			-o-animation: action02 2s ease-in-out infinite alternate;
			-ms-animation: action02 2s ease-in-out infinite alternate;
			@media (max-width: 1300px) {
				max-width: unset;
			}
			@media (max-width: 768px) {
				max-width: unset;
			}
		}

		.main-img-03 {
			width: 20vw;
			max-width: 380px;
			position: absolute;
			top: 0px;
			left: 0;
			transform: translate(-235%, 125%);
			-webkit-transform: translate(-235%, 125%);
			-moz-transform: translate(-235%, 125%);
			-o-transform: translate(-235%, 125%);
			-ms-transform: translate(-235%, 125%);
			z-index: 1;
			animation: action03 2.5s ease-in-out infinite alternate;
			-webkit-animation: action03 2.5s ease-in-out infinite alternate;
			-moz-animation: action03 2.5s ease-in-out infinite alternate;
			-o-animation: action03 2.5s ease-in-out infinite alternate;
			-ms-animation: action03 2.5s ease-in-out infinite alternate;
			@media (max-width: 1300px) {
				max-width: unset;
			}
			@media (max-width: 768px) {
				max-width: unset;
			}
		}

		.main-img-04 {
			width: 25vw;
			max-width: 390px;
			position: absolute;
			top: 25px;
			left: 0;
			transform: translate(-220%, 250%);
			-webkit-transform: translate(-220%, 250%);
			-moz-transform: translate(-220%, 250%);
			-o-transform: translate(-220%, 250%);
			-ms-transform: translate(-220%, 250%);
			z-index: 1;
			animation: action04 1.5s ease-in-out infinite alternate;
			-webkit-animation: action04 1.5s ease-in-out infinite alternate;
			-moz-animation: action04 1.5s ease-in-out infinite alternate;
			-ms-animation: action04 1.5s ease-in-out infinite alternate;
			-o-animation: action04 1.5s ease-in-out infinite alternate;
			@media (max-width: 768px) {
				max-width: unset;
			}
		}
		// ARABIC Section
		@keyframes action01-ar {
			0% {
				transform: translate(-370%, 10%);
				-webkit-transform: translate(-370%, 10%);
				-moz-transform: translate(-370%, 10%);
				-o-transform: translate(-370%, 10%);
				-ms-transform: translate(-370%, 10%);
			}
			100% {
				transform: translate(-370%, 5%);
				-webkit-transform: translate(-370%, 5%);
				-moz-transform: translate(-370%, 5%);
				-o-transform: translate(-370%, 5%);
				-ms-transform: translate(-370%, 5%);
			}
		}
		.main-img-01-ar {
			width: 25vw;
			position: absolute;
			top: 0px;
			left: 0;
			transform: translate(-370%, 10%);
			-webkit-transform: translate(-370%, 10%);
			-moz-transform: translate(-370%, 10%);
			-o-transform: translate(-370%, 10%);
			z-index: 1;
			animation: action01-ar 2s ease-in-out infinite alternate;
			-webkit-animation: action01-ar 2s ease-in-out infinite alternate;
			-moz-animation: action01 2s ease-in-out infinite alternate;
			-o-animation: action01 2s ease-in-out infinite alternate;
			-ms-animation: action01 2s ease-in-out infinite alternate;

			@media (max-width: 1300px) {
				max-width: unset;
			}
			@media (max-width: 768px) {
				max-width: unset;
			}
		}
		@keyframes action02-ar {
			0% {
				transform: translate(-380%, 150%);
				-webkit-transform: translate(-380%, 150%);
				-moz-transform: translate(-380%, 150%);
				-o-transform: translate(-380%, 150%);
				-ms-transform: translate(-380%, 150%);
			}
			100% {
				transform: translate(-380%, 155%);
				-webkit-transform: translate(-380%, 155%);
				-moz-transform: translate(-380%, 155%);
				-o-transform: translate(-380%, 155%);
				-ms-transform: translate(-380%, 155%);
			}
		}
		.main-img-02-ar {
			width: 25vw;
			position: absolute;
			top: 0px;
			left: 0;
			transform: translate(-380%, 155%);
			-webkit-transform: translate(-380%, 155%);
			-moz-transform: translate(-380%, 155%);
			-o-transform: translate(-380%, 155%);
			-ms-transform: translate(-380%, 155%);

			z-index: 1;
			animation: action02-ar 2s ease-in-out infinite alternate;
			-webkit-animation: action02-ar 2s ease-in-out infinite alternate;
			-moz-animation: action02-ar 2s ease-in-out infinite alternate;
			-ms-animation: action02-ar 2s ease-in-out infinite alternate;
			-o-animation: action02-ar 2s ease-in-out infinite alternate;
			@media (max-width: 1300px) {
				max-width: unset;
			}
			@media (max-width: 768px) {
				max-width: unset;
			}
		}
		@keyframes action03-ar {
			0% {
				transform: translate(-350%, 120%);
				-webkit-transform: translate(-350%, 120%);
				-moz-transform: translate(-350%, 120%);
				-o-transform: translate(-350%, 120%);
				-ms-transform: translate(-350%, 120%);
			}
			100% {
				transform: translate(-350%, 125%);
				-webkit-transform: translate(-350%, 125%);
				-moz-transform: translate(-350%, 125%);
				-o-transform: translate(-350%, 125%);
				-ms-transform: translate(-350%, 125%);
			}
		}
		.main-img-03-ar {
			width: 20vw;
			position: absolute;
			top: 0px;
			left: 0;
			transform: translate(-350%, 100%);
			-webkit-transform: translate(-350%, 100%);
			-moz-transform: translate(-350%, 100%);
			-o-transform: translate(-350%, 100%);
			z-index: 1;
			animation: action03-ar 2.5s ease-in-out infinite alternate;
			-webkit-animation: action03-ar 2.5s ease-in-out infinite alternate;
			-moz-animation: action03 2.5s ease-in-out infinite alternate;
			-o-animation: action03 2.5s ease-in-out infinite alternate;
			-ms-animation: action03 2.5s ease-in-out infinite alternate;
			@media (max-width: 1300px) {
				max-width: unset;
			}
			@media (max-width: 768px) {
				max-width: unset;
			}
		}
		@keyframes action04-ar {
			0% {
				transform: translate(-285%, 250%);
				-webkit-transform: translate(-285%, 250%);
				-moz-transform: translate(-285%, 250%);
				-o-transform: translate(-285%, 250%);
				-ms-transform: translate(-285%, 250%);
			}
			100% {
				transform: translate(-285%, 245%);
				-webkit-transform: translate(-285%, 245%);
				-moz-transform: translate(-285%, 245%);
				-o-transform: translate(-285%, 245%);
				-ms-transform: translate(-285%, 245%);
			}
		}
		.main-img-04-ar {
			width: 25vw;
			position: absolute;
			top: 25px;
			left: 0;
			transform: translate(-285%, 250%);
			-webkit-transform: translate(-285%, 250%);
			-moz-transform: translate(-285%, 250%);
			-o-transform: translate(-285%, 250%);
			-ms-transform: translate(-285%, 250%);
			z-index: 1;
			animation: action04-ar 1.5s ease-in-out infinite alternate;
			-webkit-animation: action04-ar 1.5s ease-in-out infinite alternate;
			-moz-animation: action04 1.5s ease-in-out infinite alternate;
			-o-animation: action04 1.5s ease-in-out infinite alternate;
			-ms-animation: action04 1.5s ease-in-out infinite alternate;

			@media (max-width: 1300px) {
				max-width: unset;
			}
			@media (max-width: 768px) {
				max-width: unset;
			}
		}
	}

	.mobile-image {
		display: none;
		@media (max-width: 480px) {
			display: block;

			img {
				width: 100vw;
				height: auto;
			}
		}
	}
}

.main-clients {
	text-align: center;
	margin-bottom: 7rem;
	margin: auto;
	width: 80%;
	.slick-initialized .slick-slide {
		display: inline-block;
		vertical-align: middle;
		float: none;
	}
	h3 {
		margin-bottom: 0rem;
		font-size: 2rem;
		font-family: "Gotham-Light";
		color: #757575;
		opacity: 1;
		line-height: 4rem;
		font-weight: 900 !important;
		@media (max-width: 1000px) {
			margin: 0;
		}
	}
	.slide {
		transform: scale(0.9);
		transition: transform 500ms;
		filter: grayscale(100%);
		padding: 0 5%;

		img {
			width: 100%;
			margin: 10px auto;
			height: 50%;
			max-width: 150px;
		}
	}

	.activeSlide {
		opacity: 1;
		transition: all 0.3s ease-out;
		transform: scale(1.05);
		filter: grayscale(0%);
		@media (max-width: 768px) {
			transform: scale(0.9);
		}
	}

	.slider-image {
		cursor: pointer;
	}
	@media (max-width: 1000px) {
		text-align: center;
		margin-bottom: 7rem;
		margin: auto;
		width: 80%;
		margin-top: 5rem;
	}
}

@media (max-width: 1000px) {
	@keyframes action01 {
		0% {
			top: 0;
		}
		50% {
			top: 15px;
		}
		100% {
			top: 0;
		}
	}
	@keyframes action02 {
		0% {
			top: 10px;
		}
		50% {
			top: 0px;
		}
		100% {
			top: 10px;
		}
	}
	@keyframes action03 {
		0% {
			top: 0px;
		}
		50% {
			top: 10px;
		}
		100% {
			top: 0px;
		}
	}
	@keyframes action04 {
		0% {
			top: 0px;
		}
		50% {
			top: 20px;
		}
		100% {
			top: 0px;
		}
	}

	.main-left {
		h1 {
			margin-bottom: 4%;
		}
		.main-img-01 {
			width: 100%;
			animation: action01 3s linear infinite;
			position: relative;
			@media (max-width: 1000px) {
				width: 30%;
				max-width: 30%;
			}
			@media (max-width: 500px) {
				width: 50%;
				max-width: 50%;
			}
		}
		.main-img-02 {
			width: 100%;
			animation: action02 3s linear infinite;
			position: relative;
			@media (max-width: 1000px) {
				width: 30%;
				max-width: 30%;
			}
			@media (max-width: 500px) {
				width: 50%;
				max-width: 50%;
			}
		}
		.main-img-03 {
			width: 100%;
			animation: action03 4s linear infinite;
			position: relative;
			@media (max-width: 1000px) {
				width: 30%;
				max-width: 30%;
			}
			@media (max-width: 500px) {
				width: 50%;
				max-width: 50%;
			}
		}
		.main-img-04 {
			width: 100%;
			animation: action04 4s linear infinite;
			position: relative;
			@media (max-width: 1000px) {
				width: 30%;
				max-width: 30%;
			}
			@media (max-width: 500px) {
				width: 50%;
				max-width: 50%;
			}
		}
	}
}

.web-text {
	height: 128px;
	vertical-align: middle;
	display: table-cell;

	@media (max-width: 1000px) {
		display: flow-root;
		height: 5vh;
	}
}
