$white-color: #fff;
$primary-color: #0384e5;
$secondary-color: #757575;

.bcw-whatsapp-learning-section {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  background-color: $white-color;

  @media (max-width: 1000px) {
    flex-wrap: wrap-reverse;
  }

  .whatsapp-learning-content {
    width: 80vw;
    display: flex;
    min-width: 80vw;
    max-width: 80vw;
    padding: 40px 0;
    align-items: center;
    border-radius: 4rem;
    justify-content: center;
    background-color: $white-color;

    @media (max-width: 768px) {
      padding: 40px 0;
      flex-direction: column;
    }

    .content-left {
      width: 30%;
      height: 80%;
      display: flex;
      min-width: 30%;
      max-width: 30%;
      align-items: center;

      @media (max-width: 761px) {
        display: flex;
        min-width: 100%;
        max-width: 100%;
      }

      .whatsapp-learning-info {
        @media (max-width: 761px) {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }

        .whatsapp-learning-title {
          font-size: 3rem;
          font-weight: bold;
          color: $primary-color;
        }

        .whatsapp-learning-subtitle {
          font-size: 4rem;
          font-weight: bold;
          color: chartreuse;
        }

        .whatsapp-learning-description {
          color: $secondary-color;
          width: 80%;

          @media (max-width: 761px) {
            text-align: center;
          }
        }

        .whatsapp-learning-learn-more {
          margin-right: 3rem;
          display: inline-block;
          background: $primary-color;
          min-width: 179px;
          color: var(--white);
          text-decoration: none;
          font-size: 2rem;
          line-height: 3.8rem;
          border-radius: 1rem;
          -webkit-transition: all 1s;
          transition: all 1s;
          padding: 1rem 2rem;
          text-align: center;
          cursor: pointer;

          @media (max-width: 761px) {
            margin: 0;
          }
        }
      }
    }

    .content-right {
      width: 60%;
      min-width: 60%;
      max-width: 60%;
      //padding: 100px;

      @media (max-width: 500px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      }

      .whatsapp-learning-img {
        width: 100%;

        @media (max-width: 1000px) {
          padding: 40px;
        }

        @media (max-width: 500px) {
          padding: 40px;
        }
      }

      .whatsapp-learning-img-ar {

      }
    }
  }
}