$primary-color: #0384e5;
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

.bcw-main {
  width: 80%;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;

  h1 {
    line-height: 1;
    font-size: 5rem;
    font-weight: bold;
    font-family: "Gotham";

    @media (max-width: 1000px) {
      font-size: 5rem;
      margin-top: 3vh;
      text-align: center;
    }

    @media (max-width: 500px) {
      font-size: 4rem;
      margin-top: 10%;
      text-align: center;
    }
    @media (max-width: 768px) {
      font-size: 3rem;
      padding: 0;
    }
    color: $primary-color;
  }

  .bcw-sales-work-mobile {
    color: chartreuse;
    font-weight: bold;

    @media (max-width: 1000px) {
      margin-top: 3vh;
      font-size: 4rem;
      text-align: center;
    }

    @media (max-width: 768px) {
      font-size: 2rem;
    }

    @media (max-width: 500px) {
      margin-top: 5%;
      font-size: 3rem;
      text-align: center;
    }
  }

  .bcw-main-img-main-mobile {
    width: 100vw;
    max-width: 390px;
    position: relative;
    transform: translate(-8%, 15%);
    -o-transform: translate(-8%, 15%);
    -ms-transform: translate(-8%, 15%);
    -moz-transform: translate(-8%, 15%);
    -webkit-transform: translate(-8%, 15%);
    animation: action01 2s ease-in-out infinite alternate;
    -o-animation: action01 2s ease-in-out infinite alternate;
    -ms-animation: action01 2s ease-in-out infinite alternate;
    -moz-animation: action01 2s ease-in-out infinite alternate;
    -webkit-animation: action01 2s ease-in-out infinite alternate;

    @media (max-width: 1300px) {
      max-width: unset;
    }

    @media (max-width: 1000px) {
      max-width: 390px;
      align-self: center;
      margin-bottom: 40px;
    }

    @media (max-width: 768px) {
      max-width: 390px;
    }

    @media (max-width: 768px) {
      max-width: 390px;
    }

    @media (max-width: 500px) {
      max-width: 290px;
      align-self: center !important;
    }
  }

  .bcw-main-img-main-mobile-ar {
    top: 0;
    left: 0;
    z-index: 1;
    width: 25vw;
    position: absolute;
    transform: translate(-370%, 10%);
    -o-transform: translate(-370%, 10%);
    -moz-transform: translate(-370%, 10%);
    -webkit-transform: translate(-370%, 10%);
    animation: action01-ar 2s ease-in-out infinite alternate;
    -o-animation: action01 2s ease-in-out infinite alternate;
    -ms-animation: action01 2s ease-in-out infinite alternate;
    -moz-animation: action01 2s ease-in-out infinite alternate;
    -webkit-animation: action01-ar 2s ease-in-out infinite alternate;

    @media (max-width: 1300px) {
      max-width: unset;
    }

    @media (max-width: 768px) {
      max-width: unset;
    }
  }

  .bcw-unlock-true-mobile {
    color: #757575;
    font-family: Gotham-light;
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: 3rem;
    margin-top: 3rem;
    font-weight: normal;
    opacity: 0.8;

    @media (max-width: 1100px) {
      width: 75%;
    }

    @media (max-width: 1000px) {
      font-size: 2.3rem;
      text-align: center;
      margin-top: 5rem;
      width: 100%;
    }

    @media (max-width: 500px) {
      font-size: 2.3rem;
      text-align: center;
      margin-top: 2rem;
      width: 100%;
    }

    @media (max-width: 768px) {
      padding: 0;
    }
  }

  .bcw-main-demo-mobile {
    margin-right: 3rem;
    display: inline-block;

    @media (max-width: 1000px) {
      width: 200px;
      align-self: center;
      margin-bottom: 1rem;
    }

    @media (max-width: 768px) {
      margin-bottom: 1rem;
    }

    @media (max-width: 480px) {
      margin-right: 0;
      margin-bottom: 1rem;
    }

    background: $primary-color;
    min-width: 179px;
    color: var(--white);
    text-decoration: none;
    font-size: 2rem;
    line-height: 3.8rem;
    border-radius: 1rem;
    -webkit-transition: all 1s;
    transition: all 1s;
    padding: 1rem 2rem;
    text-align: center;
    cursor: pointer;

    &:hover {
      background: $primary-color;
    }
  }

  .bcw-try-for-free-mobile {
    display: inline-block;
    margin-right: 3rem;
    border: 1px solid;

    @media (max-width: 1000px) {
      width: 200px;
      align-self: center;
      margin-bottom: 1rem;
    }

    @media (max-width: 480px) {
      margin-right: 1rem;
    }

    background: white;
    min-width: 179px;
    color: var(--demo-gradient-blue2);
    text-decoration: none;
    font-size: 2rem;
    line-height: 3.8rem;
    border-radius: 1rem;
    -webkit-transition: all 1s;
    transition: all 1s;
    padding: 1rem 2rem;
    text-align: center;
    cursor: pointer;

    &:hover {
      background: $primary-color;
    }
  }
}