.contactContainer {
	height: 100vh;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-evenly;

	@media (max-width: 500px) {
		height: 100%;
		background: #0584e5;
		border-top: 0.2rem solid white;
	}

	img.contactBg {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		z-index: -1;
	}
}

.contactHeader {
	display: flex;
	align-items: start;
	flex-direction: column;
	padding: 0 10%;

	@media (max-width: 768px) {
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 0 10%;
		text-align: center;
		align-content: space-around;
		justify-content: space-between;
	}

	@media (max-width: 500px) {
		width: 100%;
		padding: 5%;
	}

	h2 {
		font-size: 5rem;
		font-weight: bold;
		color: white;

		@media (max-width: 768px) {
			font-size: 3rem;
		}
	}

	h3 {
		font-size: 3rem;
		color: var(--white);
		opacity: 0.8;
		font-weight: normal;

		@media (max-width: 768px) {
			font-size: 3rem;
		}
	}
}

.contactUsBody {
	flex-direction: row;

	@media (max-width: 500px) {
		display: block;
	}

	@media (max-width: 768px) {
		padding: 0 5%;
	}

	justify-content: space-between;
	margin: 0;
	width: 100%;
	padding: 0 10%;

	img {
		margin: 0 3rem;

		@media (max-width: 900px) {
			margin: 0 0 0 30px !important;
		}
	}
}

.contactUsForm {
	width: 60%;

	@media (max-width: 768px) {
		width: 50%;
	}

	@media (max-width: 500px) {
		width: 100%;
	}

	.formInput {
		background: white;
		border: none;
		width: 100%;

		@media (max-width: 500px) {
			width: 100%;
		}

		height: 5rem;
		border-radius: 5px;
		padding: 15px;
		font-size: 15px;
		margin-bottom: 20px;

		:focus {
			outline: none;
		}
	}

	.contactSendButton {
		background: white;
		border: none;
		padding: 1rem 5rem;
		border-radius: 10px;
		margin-top: 10px;
		color: #0584e5;
		font-size: 2rem;
		cursor: pointer;
		transition: all 0.5s ease;

		@media (max-width: 500px) {
			width: 100%;
		}

		&:hover {
			box-shadow: 19px 28px 30px rgb(38 38 38 / 20%);
			cursor: pointer;
		}
	}
}

.generalInformation {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	text-align: left;
	margin-left: 10rem;
	width: 50%;

	@media (max-width: 1200px) {
		margin-left: 0;
	}

	@media (max-width: 500px) {
		display: block;
		width: 100% !important;
		margin: 10% 0 !important;
	}

	@media (max-width: 768px) {
		width: 45%;
		margin: 0 5%;
	}

	.infoItem {
		display: flex;
		margin: 1.5rem 0;

		div {
			display: flex;
			flex-direction: column;
			text-align: left;
			justify-content: space-evenly;
		}

		.title {
			font-size: 2rem;
			color: var(--white);
		}

		.description {
			font-family: Gotham-Light;
			font-weight: 100;
			line-height: 2rem;
			margin-top: 1rem;
			font-size: 1.5rem;
			color: var(--white);
		}
	}

	.infoItem-ar {
		display: flex;
		margin: 1.5rem 0;
		flex-direction: row;
		flex-wrap: nowrap;
		align-content: flex-start;
		justify-content: flex-start;
		align-items: center;

		div {
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			flex-wrap: nowrap;
			align-content: center;
			align-items: flex-start;
		}

		.title {
			font-size: 2rem;
			padding: 0 15px;
			color: var(--white);

			@media (max-width: 1200px) {
				font-size: 16px;
			}
		}

		.description {
			font-family: Gotham-Light;
			font-weight: 100;
			line-height: 2rem;
			margin-top: 1rem;
			font-size: 1.5rem;
			text-align: start;
			padding: 0 15px;
			color: var(--white);

			@media (max-width: 1200px) {
				font-size: 16px;
			}
		}
	}
}

.contact-error-message {
	padding: 8px 0;
	color: #842029;
	font-size: 1.5rem;
	font-weight: 800;
}

.Alert-red-Message {
	padding: 15px;
	background-color: #f8d7da;
	border-radius: 5px;
	margin-bottom: 10px;
	font-size: 1.5rem;
	color: #842029;
	font-weight: 700;
}

.Alert-green-Message {
	padding: 15px;
	background-color: #d1e7dd;
	border-radius: 5px;
	margin-bottom: 10px;
	font-size: 1.5rem;
	color: #0f5132;
	font-weight: 700;
}

.contact-input {
	padding: 15px !important;
	font-size: 18px !important;
	border-radius: 5px !important;
	margin: 10px 0 !important;

	&:disabled {
		background: #dddddd !important;
		color: #a0b0bf !important;
	}
}

.m-auto {
	margin: auto !important;
}