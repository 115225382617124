@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

.reviews-section-container {
	height: 100%;

	@media (max-width: 480px) {
		height: 100% !important;
	}
	@media (max-width: 768px) {
		height: 135vh;
	}

	.reviews-section {
		position: relative;
		height: 100%;
		@media (max-width: 768px) {
			height: 135vh;
		}
		@media (max-width: 480px) {
			height: 100%;
			padding-bottom: 10%;
		}

		img.reviewsBg {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: -1;

			@media (max-width: 480px) {
				height: 100%;
			}
		}

		text-align: center;

		.reviewsSectionTitle {
			color: var(--white);
			font-size: 5rem;
			font-weight: bold;
			margin-top: 5rem;
			padding-top: 5rem;

			@media (max-width: 480px) {
				font-size: 3rem;
				padding-top: 10rem;
			}
			@media (max-width: 768px) {
				font-size: 3rem;
				padding-top: 5%;
			}
		}

		.reviewsSectionSubTitle {
			color: var(--white);
			font-family: Gotham-Light-Italic;
			font-size: 3rem;
			/*margin-top: 3rem;*/

			@media (max-width: 480px) {
				font-size: 2.5rem;
			}
		}

		.reviews {
			margin-top: 3rem;
			display: flex;
			justify-content: space-between;

			@media (max-width: 480px) {
				flex-direction: column;
				padding: 0 !important;
			}
			@media (max-width: 768px) {
				padding: 0 !important;
			}
		}

		.demo-demo {
			display: inline-block;
			margin-right: 3rem;
			background: var(--white);
			color: var(--darkBlue);
			text-decoration: none;
			font-size: 2rem;
			line-height: 3.8rem;
			border-radius: 1rem;
			-webkit-transition: all 0.3s;
			transition: all 0.3s;
			padding: 1rem 2rem;
			text-align: center;

			&:hover {
				background: #fff;
				color: var(--darkBlue);
				box-shadow: 0 0.4rem 0.4rem rgba(83, 100, 255, 0.32);
			}
		}
	}

	.reviews-card {
		background: #fff;
		border-radius: 2rem;
		margin: 5rem 2rem;
		position: relative;
		// max-width: 300px;
		border: 2px solid var(--lightBlue);
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		box-shadow: 4px 5px 30px 4px rgb(38 38 38 / 20%);
		transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
		@media (max-width: 1100px) {
			margin: 0;
		}
	}

	.reviews-card-side {
		// height: 50rem;
		width: 100%;
		cursor: pointer;

		&:hover {
			// box-shadow: 19px 28px 30px rgb(38 38 38 / 30%);
			transform: scale(1.05);
			@media (max-width: 500px) {
				transform: scale(1);
			}
		}
	}

	.review-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-direction: row;
		align-items: center;
		padding: 5%;
		@media (max-width: 768px) {
			height: 100px;
		}
		.review-quotation {
			margin: auto;
			text-align: right;
			width: 40%;

			@media (max-width: 768px) {
				.review-quotes {
					width: 50%;
					height: 50%;
					margin: 0 0 0 auto;
				}
			}
		}

		.review-partner {
			width: 60%;
			margin: auto;
		}

		img {
			height: 100%;
		}

		.review-company {
			height: auto;
			width: 75%;
			margin: 0;
			@media (max-width: 768px) {
				width: 80%;
				margin-right: auto;
			}
		}

		.review-quotes {
			height: unset;
			width: 50%;
			margin: 0 0 0 auto;
		}
	}

	.review-content {
		height: 285px;
		display: flex;
		padding: 15% 5%;
		font-size: 18px;
		text-align: start;
		color: #5a6e7e;
		flex-direction: column;
		align-content: flex-end;
		justify-content: center;
		align-items: flex-start;
		flex-wrap: nowrap;
		@media (max-width: 1100px) {
			font-size: 18px;
		}
		@media (max-width: 768px) {
			font-size: 18px;
			padding: 10% 5%;
			height: 250px;
		}
		@media (max-width: 480px) {
			font-size: 14px !important;
			height: 265px !important;
		}
	}

	.review-author {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		text-align: start;
		height: 135px;
		padding: 5%;

		@media (max-width: 320px) {
			height: 135px !important;
		}
		@media (max-width: 500px) {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			text-align: start;
			height: 150px;
			padding: 5%;
		}
		.author-details {
			margin-left: 2.5rem;
			@media (max-width: 768px) {
				margin-left: 10px;
			}
			@media (max-width: 500px) {
				margin-left: 10px;
				width: 70%;
			}
		}

		.author-name {
			font-size: 2rem;
			color: #0384e5;
			font-weight: bold;
			@media (max-width: 768px) {
				font-size: 14px;
			}
			@media (max-width: 480px) {
				font-size: 12px;
				margin: 0 !important;
			}
		}

		.author-job {
			color: #606060;
			font-size: 1.7rem;
			line-height: 1.75rem;
			font-family: Gotham-light-italic;
			@media (max-width: 768px) {
				font-size: 14px;
			}
			@media (max-width: 480px) {
				line-height: 2rem;
				font-size: 12px;
				margin: 0 !important;
			}
		}

		.author-organization {
			color: #606060;
			line-height: 2rem;
			font-size: 1.7rem;
			font-family: Gotham-light-italic;
			@media (max-width: 768px) {
				width: 100%;
				font-size: 14px;
			}
			@media (max-width: 480px) {
				font-size: 14px;
				margin: 0 !important;
			}
		}

		.author-image {
			width: 25%;
			@media (max-width: 768px) {
				width: 25%;
			}
			@media (max-width: 480px) {
				width: 30%;
			}

			img {
				max-height: 100%;
				max-width: 80px;
				border-radius: 50%;
				border: 0;
			}
		}
	}

	.review-author-ar {
		width: 100%;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		text-align: justify;
		padding: 5%;
		height: 135px;
		align-content: flex-start;
		justify-content: center;
		.author-details {
			margin: 0 2.5rem;
			width: 75%;
			@media (max-width: 768px) {
				margin: 0 10px;
			}
		}

		.author-name {
			font-size: 2rem;
			color: #0384e5;
			font-weight: bold;
			@media (max-width: 768px) {
				font-size: 14px;
			}
			@media (max-width: 480px) {
				font-size: 20px;
			}
		}

		.author-job {
			color: #606060;
			font-size: 1.7rem;
			line-height: 1.75rem;
			font-family: Gotham-light-italic;
			@media (max-width: 768px) {
				font-size: 14px;
			}
			@media (max-width: 480px) {
				line-height: 2rem;
				font-size: 20px;
			}
		}

		.author-organization {
			color: #606060;
			line-height: 2rem;
			font-size: 1.7rem;
			font-family: Gotham-light-italic;
			@media (max-width: 768px) {
				width: 100%;
				font-size: 14px;
			}
			@media (max-width: 480px) {
				font-size: 20px;
			}
		}

		.author-image {
			width: 25%;
			@media (max-width: 768px) {
				width: 25%;
			}
			@media (max-width: 480px) {
				width: 50%;
			}

			img {
				max-height: 100%;
				max-width: 100%;
				border-radius: 50%;
				border: 0;
			}
		}
	}

	.reviews-demo {
		padding-bottom: 5rem;
		@media (max-width: 500px) {
			padding: 5rem 0;
		}
		.reviews-demo-title {
			font-family: Gotham-light-italic;
			line-height: 4rem;
			font-weight: bold;
			color: var(--white);
			font-size: 4rem;
			padding: 0 5rem;

			@media (max-width: 500px) {
				font-size: 2.5rem;
			}
			@media (max-width: 768px) {
				font-size: 3rem;
			}
		}

		.reviews-demo-btn {
			// margin: 30px 0;
			.review-btn {
				padding: 10px 60px;
				border-radius: 1rem;
				color: #0584e5;
				background-color: white;
				text-decoration: none;
				top: 0;
				border: 0;
				margin: 25px 0;
				font-size: 18px;
				transition: all 0.3s ease-out;
				cursor: pointer;
				box-shadow: 3px 4px 20px -8px black;
				@media (max-width: 768px) {
					font-size: 2rem;
				}
				&:hover {
					background: #fff;
					color: var(--darkBlue);
					box-shadow: 0 0.4rem 0.4rem rgba(83, 100, 255, 0.32);
				}
			}
		}
	}

	.reviews-demo-button {
		display: inline-block;
		margin-top: 3rem;
		margin-right: 3rem;

		@media (max-width: 480px) {
			margin-right: 0;
		}

		background: #def0f2;
		color: var(--darkBlue);
		text-decoration: none;
		font-size: 2rem;
		line-height: 3.8rem;
		border-radius: 1rem;
		-webkit-transition: all 0.3s;
		transition: all 0.3s;
		padding: 1rem 2rem;
		text-align: center;

		&:hover {
			background: var(--white);
		}
	}
}

.slide {
	transform: scale(0.9);
	transition: transform 500ms;

	img {
		width: 100%;
		margin: auto;
	}
}

.activeSlide {
	opacity: 1;
	transition: all 0.3s ease-out;
	transform: scale(1);
	@media (max-width: 769px) {
		transform: scale(0.9);
	}
}

.slider-image {
	cursor: pointer;
}

.reviews-testemonials {
	.slick-slider {
		.slick-list {
			height: 700px;
			@media (max-width: 800px) {
				height: 601px;
			}
			@media (max-width: 700px) {
				height: 550px;
			}
			@media (max-width: 500px) {
				height: 525px;
			}
		}
	}
}
