$primary-color: #0584e5;

.faq-section-container {
	/*height: 100vh;*/
	@media (max-width: 1000px) {
		padding: 1rem 1rem;
		height: 100%;
		align-items: center;
	}
	// @media (max-width: 768px) {
	// 	height: 100vh;
	// 	align-items: center;
	// }
	@media (max-width: 500px) {
		padding: 1rem 1rem;
		height: 100%;
		align-items: center;
	}
}
.faq-section {
	height: 100%;
	display: grid;
	align-items: center;
	justify-content: grid;
	@media (max-width: 1000px) {
		height: 100%;
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		flex-direction: column;
		align-content: center;
		align-items: center;
	}
	// @media (max-width: 768px) {
	// 	display: grid;
	// 	justify-content: space-between;
	// 	align-content: space-evenly;
	// 	justify-items: stretch;
	// 	align-items: start;
	// }
	h2 {
		font-size: var(--h1);
		font-weight: bold;
		font-size: 5rem;
		text-align: center;
		margin-top: 25px;
		/*margin-bottom: -10rem;*/
		color: $primary-color;
		@media (max-width: 500px) {
			margin: 10% 0 !important;
			font-size: 3rem;
			color: #0584e5;
		}
		@media (max-width: 769px) {
			margin: 15px 0 !important;
			font-size: 3rem;
			color: #0584e5;
		}
		@media (max-width: 1000px) {
			margin: 25px 0 ;
		}
		// @media (max-width: 768px) {
		// 	font-size: 3rem;
		// }
	}
}

.faq {
	display: flex;
	padding: 1rem 10%;
	@media (max-width: 1340px) {
		padding: 1rem 10rem;
	}
	@media (max-width: 1200px) {
		padding: 1rem 1rem;
	}
	@media (max-width: 1000px) {
		padding: 1rem 1rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	@media (max-width: 1000px) {
		padding: 1rem 1rem;
	}
	// @media (max-width: 768px) {
	// 	padding: 0;
	// 	display: inline-flex;
	// 	justify-content: space-between;
	// 	align-items: stretch;
	// 	flex-direction: row;
	// 	flex-wrap: nowrap;
	// 	align-content: space-between;
	// }
	@media (max-width: 500px) {
		display: inline-flex;
		justify-content: space-between;
		align-items: stretch;
		flex-direction: column;
		flex-wrap: nowrap;
		align-content: space-between;
	}

	.faq-row-wrapper {
		width: 50%;
		margin: 1rem;
		@media (max-width: 500px) {
			width: 100%;
			margin: 0;
			background: transparent;
		}
		@media (max-width: 1000px) {
			width: 100%;
			margin: 0;
		}
		.faq-title {
			font-size: 5rem;
			color: $primary-color;
		}

		.faq-body {
			.faq-row {
				border-radius: 1rem;
				margin-bottom: 1.4rem;
				padding-left: 1.4rem;
				box-shadow: 1px 1px 6px 1px #d8e7fd8a;
				border: 1px solid #d8e7fd;

				.row-title {
					color: #8998a0;
					font-family: "Gotham";
					padding: 2rem 1rem;
					span {
						color: rgba(128, 128, 128, 0.5);
						// font-size: 4rem;
						margin-top: 1rem;
						transform: none;
					}
					// @media (max-width: 768px) {
					// 	padding: 10px 0;
					// 	font-size: 14px;
					// }
				}

				.row-content {
					.row-content-text {
						color: #8998a0;
						// @media (max-width: 768px) {
						// 	padding: 10px 0;
						// 	font-size: 14px;
						// }
					}
				}
				@media (max-width: 500px) {
					background-color: white;
				}
				@media (max-width: 768px) {
				}
			}
		}
	}
}

.faq-row-wrapper {
	.faq-title {
	}

	.faq-body {
		.faq-row {
			.row-title {
				@media (max-width: 800px) {
					font-size: 16px !important;
				}
			}

			.row-content {
				.row-content-text {
					padding: 0 1rem 2rem 1rem !important;
					@media (max-width: 800px) {
						padding: 0 0 1rem 0 !important;
					}
				}
			}
		}
	}
}
