$primary-color: #0384e5;

.about-section {
    .about-header {
        height: 75vh;
        background-color: $primary-color;
        display: flex;
        background: url("https://eflow-static02-landing.s3.us-west-2.amazonaws.com/media/elements/team-800px.png");
        background-size: cover;
        background-position: top;
        .about-title {
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: center;
            align-items: flex-end;
            width: 100%;
            color: white;
            font-family: "Gotham";
            font-size: 5rem;
            margin-bottom: 10rem;
            @media (max-width: 768px) {
                margin-top: 10%;
                padding: 0 5%;
                line-height: 5rem;
            }
            @media (max-width: 500px) {
                font-size: 5rem;
                line-height: 5rem;
                padding: 0 5%;
            }
        }
        .about-sub-title {
            color: white;
            font-size: 1.8rem;
            margin-top: 2.5%;
        }
        .about-txt {
            padding: 0 10%;
            color: white;
            margin-top: 1rem;
            font-family: "Gotham-Light";
            //line-height: 2.2rem;
            font-size: 2rem;
            font-weight: 900;
            z-index: 1;
            opacity: 1;
            min-height: 200px;
            @media (max-width: 500px) {
                padding: 0 5%;
                z-index: 1;
            }
            @media (max-width: 900px) {
                padding: 0 5%;
                font-size: 1.5rem;
                z-index: 1;
            }
        }
    }
    .about-container {
        width: 100%;
        padding: 5%;
        .about-title {
            color: $primary-color;
            font-size: 2.5rem;
            margin-top: 3%;
            margin-bottom: 1%;
            font-family: Gotham;
            line-height: 3rem;
            @media (max-width: 768px) {
                margin-top: 10%;
            }
        }
        .about-sub-title {
            color: black;
            font-size: 1.8rem;
            margin-top: 2.5%;
        }
        .about-txt {
            color: #757575;
            margin-top: 1rem;
            text-align: justify;
            font-family: "Gotham-Light";
            //line-height: 2.2rem;
            font-size: 2rem;
            font-weight: 900;
            opacity: 0.8;
            margin-bottom: 20px;
        }
        .about-txt-01 {
            color: black;
            margin-top: 1rem;
            text-align: justify;
            font-family: Gotham-light;
            //line-height: 3rem;
            font-size: 2rem;
            font-weight: normal;
            opacity: 0.8;
            .about-email {
                font-family: gotham-light-italic;
                font-weight: 900;
                color: #757575;
            }
        }
        .about-list {
            margin-top: 2rem;
            li {
                color: #757575;
                text-align: justify;
                font-family: "Gotham-Light";
                //line-height: 2.2rem;
                font-size: 2rem;
                font-weight: 900;
                opacity: 0.8;
            }
        }
    }
}
.italic {
    text-decoration: wavy;
    font-style: italic;
    text-align: start;
}
.about-txt-header {
    color: #0384e5;
    font-size: 2.5rem;
    margin-top: 3%;
    margin-bottom: 1%;
    font-family: Gotham;
    line-height: 3rem;
}
.about-reference {
    list-style-type: "-";
}
.body-img {
    width: 100%;
}
.science-container {
    column-gap: 10px;
    column-count: 4;
    margin: auto;
    text-align: center;
    @media (max-width: 800px) {
        column-count: 2;
    }
    @media (max-width: 500px) {
        column-count: 1;
    }
    .science-container-img {
        height: 120px;
        opacity: 0.8;
        @media (max-width: 1100px) {
            height: 110px;
        }
        @media (max-width: 500px) {
            height: 110px;
        }
        img {
            width: 90%;
            max-width: 250px;
            height: auto;
            padding: 5%;
        }
    }
    .science-container-div {
        //display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        .about-txt {
            //height: 140px;
            text-align: center;
            min-height: 125px;
            margin-top: 30px;
            @media (max-width: 500px) {
                height: unset;
            }
        }
        a {
            color: $primary-color !important;
            font-weight: 800 !important;
            font-family: "Gotham";
        }
    }
}
.text-center {
    text-align: center;
}
.about-images-container {
    background-color: #0584e5;
    opacity: 1 !important;
    text-align: center;
    height: 500px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    h1 {
        color: white !important;
        margin: 0;
        padding: 30px 0 30px 0;
    }
}
