$primary-color: #0384e5;

.footer-section-container {
	height: auto;
	margin-top: -15rem;
	position: relative;
	width: 100%;
	@media (min-width: 768px) {
		margin: 0;
	}

	img.footerBg {
		width: 100vw;
		height: 67rem;
		position: absolute;
		top: -4rem;
		left: 0;
		right: 0;
		z-index: -1;
	}

	.footer-section {
		padding-top: 15rem;
		@media (min-width: 768px) {
			padding: 0;
		}
	}

	.footer-links {
		display: flex;
		justify-content: flex-end;
		margin: 5rem 10%;

		@media (max-width: 768px) {
			margin: 5rem 5%;
		}
		
		html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate{
			width: unset !important;
		}

		.link {
			margin: 0 auto;
			width: 25%;
			padding: 0;

			.link-image {
				width: 50%;
				max-width: 200px;
				@media (max-width: 1200px) {
					min-width:110px;
					max-width: 200px;
				}
			}
			.link-a{
				font-family: 'Gotham-Light';
				color: #0584e5;
			}
		}

		.title {
			font-size: 2.5rem;
			font-weight: bold;
			color: $primary-color;
			margin-bottom: 3rem;
			padding: 0 10px;
		}

		.links {
			color: $primary-color;
			line-height: 2.5rem;
			list-style: none;
			padding: 0 10px;
			font-size: 1.5rem;
			cursor: pointer;
			li{
				a{
					color: $primary-color;
					text-decoration: none;
				}
			}
		}
	}

	.footer-end {
		background-color: $primary-color;
		width: 100%;
		.social-copyrights {
			margin: auto;
			padding: 2rem 0;
			p{
				color: white;
				text-align: center;
				font-weight: lighter;
				font-size: 1.7rem;
				@media (min-width: 769px) {
					font-size: 1.5rem;
					margin: 0;
				}
			}
		}
	}
}

.socials {
	width: 100%;
	display: flex;
	justify-content: start;
	margin-bottom: 5rem;
	padding: 0 10%;

	.socialsimg {
		margin-right: 1rem;
		width: 3rem;
		height: 3rem;
	}
}

.copyright {
	font-size: 1.4rem;
	color: var(--darkBlue);
}