.section-demo {
	background-image: url('../assets/img/elements-23.png');
	background-size: cover;
	display: flex;
	flex-direction: column;
	text-align: center;
	height: 40rem;
	justify-content: center;
	padding: 0 100px;
	align-items: flex-start;
	margin-bottom: 10rem;

	@media (max-width: 480px) {
		margin-bottom: 3rem;
		padding: 0 3rem;
	}
	@media (max-width: 768px) {
		height: unset;
		padding: 5%;
		background-size: cover;
		display: flex;
		flex-direction: column;
		text-align: center;
		justify-content: flex-end;
		align-items: flex-start;
		margin-bottom: 10rem;
		width: 100vw;
		align-content: space-around;
		flex-wrap: nowrap;
	}

	width: 100vw;

	.demo-title {
		font-size: 4rem;
		color: white;
		text-align: left;
		font-family: Gotham-light;
		line-height:5rem;

		.demo-title-span{
			font-family: Gotham;
		}

		@media (max-width: 480px) {
			font-size: 2.5rem;
			color: white;
		}
		@media (max-width: 768px) {
			font-size: 3rem;
		}
	}

	.demo-btn {
		display: inline-block;
		margin-top: 25px;

		@media (max-width: 480px) {
			margin-right: 0;
			width: 100%;
		}
		@media (max-width: 768px) {
			font-size: 2rem;
		}
		padding: 1.5rem 8rem;
		border-radius: 1rem;
		background-color: white;
		color: #0584e5;
		top: 0;
		border: 0;
		margin: 25px 0;
		font-size: 18px;
		transition: all 0.3s ease-out;
		cursor: pointer;
		box-shadow: 3px 4px 20px -8px black;
		text-decoration: unset;

		&:hover {
			background: #fff;
			color: #0584e5;
			box-shadow: 0 0.4rem 0.4rem rgba(83, 100, 255, 0.32);
		}
	}

}