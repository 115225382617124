$primary-color: #0384e5;

.privacy-section{
    .privacy-header{
        height: 50vh;
        background-color: $primary-color;
        display: flex;
        .privacy-left{
            display: flex;
            height: 100%;
            flex-direction: column;
            align-content: space-between;
            justify-content: center;
            align-items: flex-start;

            img{
                height: 100%;
                @media (max-width:768px){
                    display: none;
                }
            }

            .privacy-policy-title {
                padding: 0 10%;    
                color: white;
                font-size: 5rem;
                line-height: 10rem;
                margin-top:3%;
                margin-bottom:1%;
                font-family: Gotham;
                list-style: 4rem;
                @media (max-width: 768px){
                    margin-top:10%;
                    padding: 0 5%; 
                    line-height: 5rem;
                }
                @media (max-width: 500px){
                    font-size: 5rem;
                    line-height: 5rem;
                    padding: 0 5%;
                }
            }
            .privacy-policy-sub-title {
                color: white;
                font-size: 1.8rem;
                margin-top: 2.5%;
            }
            .privacy-policy-txt{
                padding: 0 10%;   
                color: white;
                margin-top: 1rem;
                font-family: 'Gotham-Light';
                line-height: 2.2rem;
                font-size: 2rem;
                font-weight: 900;
                z-index: 1;
                opacity: 1;
                @media (max-width:500px){
                    padding: 0 5%;
                    z-index: 1;
                }
                @media (max-width:900px){
                    padding: 0 5%;
                    font-size: 1.5rem;
                    z-index: 1;
                }
            }
        }
    }
    .privacy-container{
        width: 100%;
        padding: 5%;
        .privacy-policy-title {
            color: $primary-color;
            font-size: 2.5rem;
            margin-top:3%;
            margin-bottom:1%;
            font-family: Gotham;
            line-height: 3rem;
            @media (max-width: 768px){
                margin-top:10%;
            }
        }
        .privacy-policy-sub-title {
            color: black;
            font-size: 1.8rem;
            margin-top: 2.5%;
        }
        .privacy-policy-txt{
            color: #757575;
        margin-top: 1rem;
        text-align: justify;
        font-family: 'Gotham-Light';
        line-height: 2.2rem;
        font-size: 2rem;
        font-weight: 900;
        opacity: 0.8;
        }
        .privacy-policy-txt-01{
            color: black;
            margin-top: 1rem;
            text-align: justify;
            font-family: Gotham-light;
            line-height: 3rem;
            font-size: 2rem;
            font-weight: normal;
            opacity: 0.8;
            .privacy-policy-email{
                font-family:gotham-light-italic;
                font-weight:900;
                color: #757575;
            }
        }
        .privacy-policy-list{
            margin-top: 2rem;
            li{
                color: #757575;
                text-align: justify;
                font-family: 'Gotham-Light';
                line-height: 2.2rem;
                font-size: 2rem;
                font-weight: 900;
                opacity: 0.8;
            }
        }
    }
}