$primary-color: #0384e5;

.bcw-driving-image {
  display: flex !important;
  justify-content: center;

  @media (min-width: 1300px) {
    .bcw-main-img-01 {
      max-width: 500px!important;
    }
  }

  @media (max-width: 1300px) {
    .bcw-main-img-01 {
      padding: 20px;
      max-width: 700px!important;
    }
  }

  @media (max-width: 1000px) {
    .bcw-main-img-01 {
      padding: 20px;
      max-width: 500px!important;
    }
  }

  @media (max-width: 768px) {
    .bcw-main-img-01 {
      max-width: 300px!important;
    }
  }
}