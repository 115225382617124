$primary-color: #0384e5;

.channels-section-container {
    padding: 0 5%;
    /*height: 100vh;*/
    padding: 50px 10%;
    background-color: $primary-color;
    @media (max-width: 500px) {
        padding: 0 10px;
    }

    .channels-container {
        display: inline-flex;
        height: 100%;
        width: 100%;
        @media (max-width: 769px) {
            flex-flow: column;
            display: flex;
            padding: 50px 0;
            flex-wrap: nowrap;
            align-items: center;
            flex-direction: column;
            justify-content: space-evenly;
            align-content: center;
        }

        .channels-left-container {
            width: 100%;
            margin: auto;

            @media (max-width: 769px) {
                width: 80%;
            }
            @media (max-width: 500px) {
                margin: 0;
            }
            .channels-title {
                color: white;
                font-size: 5rem;
                font-weight: 800;
                line-height: 1;
                @media (max-width: 500px) {
                    font-size: 4rem;
                    text-align: center;
                }
                @media (max-width: 769px) {
                    font-size: 5rem;
                    line-height: 1;
                    text-align: center;
                }
            }

            .channels-sub-title {
                color: white;
                font-size: 2.5rem;
                font-weight: 100;
                margin-top: 1rem;
                line-height: 3rem;
                font-family: Gotham-light; //-italic;
                /*font-style: italic;*/
                @media (max-width: 500px) {
                    font-size: 3rem;
                    text-align: center;
                }
                @media (max-width: 769px) {
                    font-size: 3rem;
                    text-align: center;
                    margin-bottom: 50px;
                }
            }
        }
    }

    .channels-right-container {
        width: 100%;
        margin: auto;

        @media (max-width: 500px) {
            margin: 0;
        }

        .channelImg {
            width: 100%;
            /*margin-bottom: 10rem;*/
            @media (max-width: 500px) {
                margin-bottom: 0;
                width: 100% !important;
            }
            @media (max-width: 769px) {
                margin: auto !important;
                display: flex;
                width: 80%;
            }
        }
    }
}
.channel-btn {
    padding: 10px 60px;
    border-radius: 1rem;
    color: #0584e5;
    top: 0;
    background-color: white;
    border: 0;
    margin: 25px 0;
    font-size: 18px;
    transition: all 0.3s ease-out;
    cursor: pointer;
    box-shadow: 3px 4px 20px -8px black;
    @media (max-width: 500px) {
        width: unset;
        margin: 50px 0 0 0 !important;
    }
    @media (max-width: 769px) {
        display: flex;
        margin: 50px 0 0 0 !important;
        width: 75%;
        text-align: center;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    &:hover {
        background: #fff;
        color: var(--darkBlue);
        box-shadow: 0 0.4rem 0.4rem rgba(83, 100, 255, 0.32);
    }
}
