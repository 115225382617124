.modal {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
}

.modal-content {
	background-color: #fff;
	border-radius: 10px;
	padding: 20px;
	width: 80%;
	max-width: 400px;
	text-align: center; /* Center text */
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add shadow */
}

.close {
	color: #aaa;
	font-size: 24px;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
}

.close:hover {
	color: #000;
}

h2 {
	color: #38d430; /* Green title */
	margin-bottom: 10px;
}

p {
	color: #333; /* Dark text color */
}

/* Button to close the modal */
.modal-content button {
	background-color: #38d430;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s; /* Add transition */
}

.modal-content button:hover {
	background-color: #45a049; /* Darker green on hover */
}
