$primary-color: #0384e5;
$text-color: #5a6e7e;
$btn-whatsapp-green: #38d430;
$btn-whatsapp-green-disabled: #359b30;

.ar {
	direction: rtl;
}

.details-container-loader {
	// margin-top: 70px;
	// height: calc(100vh - 468px);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.error-message {
	font-size: 18px !important;
	font-family: "Gotham-Light" !important;
	margin-top: 70px;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.details-container {
	font-size: 18px !important;
	font-family: "Gotham-Light" !important;
	margin-top: 70px;

	.banner-container {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 450px;
		width: 100%;
		display: flex;

		.overlay-bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 520px;
			background-image: linear-gradient(90deg, rgb(102 103 103 / 82%), rgb(102 103 103 / 82%));
			/* Adjust the rgba values for the desired transparency */
		}

		.right-banner-section {
			width: 50%;
			height: 100%;
			padding: 50px;
			z-index: 100;

			.header {
				text-align: start;
				font-size: 36px;
				font-weight: 800;
				color: white;
			}

			.name {
				font-size: 40px;
			}
		}

		.left-banner-section {
			width: 50%;
			height: 100%;
			display: flex;
			align-items: flex-end;
			padding: 50px;
			justify-content: flex-end;
			z-index: 100;

			.left-banner-content {
				display: flex;

				.react-tel-input {
					font-family: "Gotham-Light" !important;
				}

				.react-tel-input .form-control {
					height: 100% !important;
					border: 0 !important;
					border-radius: 15px 0 0 15px !important;
				}

				.react-tel-input .flag-dropdown {
					background-color: white !important;
					border-radius: 15px 0 0 15px;
					border: 0;
				}

				.react-tel-input .selected-flag {
					&:hover {
						border-radius: 15px 0 0 15px;
						border: 0;
					}
				}

				.react-tel-input .flag-dropdown.open .selected-flag {
					border-radius: 15px 0 0 15px !important;
					border: 0 !important;
				}

				.phonecode {
					input {
						color: $text-color;
						padding: 10px 15px;
						width: 70px;
						border: 0;
						border-radius: 15px 0 0 15px;
					}
				}

				.phonenumber {
					input {
						color: $text-color;
						padding: 10px 15px;
						width: 200px;
						border: 0;
					}
				}

				.btn-enroll {
					color: white;
					background-color: $btn-whatsapp-green;
					padding: 10px 15px;
					border: 0;
					font-weight: 800;
					border-radius: 0 15px 15px 0;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 5px;

					.channel-icon {
						width: 20px;
					}
				}

				.btn-enroll-disabled {
					color: white;
					background-color: $btn-whatsapp-green-disabled;
					padding: 10px 15px;
					border: 0;
					font-weight: 800;
					border-radius: 0 15px 15px 0;
					opacity: 0.9;
					cursor: not-allowed;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 5px;

					.channel-icon {
						width: 20px;
					}
				}
			}
		}
	}

	.details-body {
		padding: 50px;
		display: flex;

		@media (max-width: 1180px) {
			padding: 35px !important;
		}

		@media (max-width: 850px) {
			padding: 10px !important;
		}

		@media (max-width: 785px) {
			flex-direction: column;
			width: 100%;
		}

		.card {
			background-color: #f2f2f2;
			padding: 50px;
			border: 0;
			border-radius: 20px;
			text-align: start;

			@media (max-width: 1180px) {
				padding: 35px !important;
			}

			@media (max-width: 850px) {
				padding: 20px !important;
			}

			@media (max-width: 785px) {
				width: 100% !important;
			}

			.author-card-title {
				color: #8969d3;
				font-weight: 800;
				font-size: 20px;
			}

			.course-details-title {
				color: #ffab4d;
				font-weight: 800;
				font-size: 20px;
			}

			.learning-objectives-title {
				color: #2196f3;
				font-weight: 800;
				font-size: 20px;
			}

			.card-text-title {
				color: #5a6e7e;
				font-size: 18px;
				font-weight: 800;
			}

			.author-div {
				display: inline-flex;
				align-items: center;
				margin: 20px 0;

				.author-img-div {
					background-color: #8998a0;
					min-width: 70px;
					min-height: 70px;
					border-radius: 50%;

					img {}

					.profile-image {
						width: 70px;
						height: 70px;
						border-radius: 50%;
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
						border: 3px solid #2196f3;
					}
				}

				h2 {
					margin: 0 10px;
					color: #8998a0;
					font-size: 20px;
					font-weight: 800;

					@media (max-width: 1180px) {
						font-size: 18px !important;
					}
				}
			}

			.text {
				color: #8998a0;
				font-weight: 400;
				text-align: start;

				@media (max-width: 1180px) {
					font-size: 18px !important;
				}

				// direction: "rtl";
			}
		}

		.left-section {
			width: 60%;
			padding: 20px;

			@media (max-width: 850px) {
				padding: 20px 10px !important;
			}

			@media (max-width: 785px) {
				width: 100% !important;
			}
		}

		.right-section {
			padding: 20px;
			width: 40%;

			@media (max-width: 850px) {
				padding: 20px 10px !important;
			}

			@media (max-width: 785px) {
				width: 100% !important;
			}
		}
	}

	.additional-media {
		padding: 50px;

		@media (max-width: 1180px) {
			padding: 35px !important;
		}

		@media (max-width: 850px) {
			padding: 10px !important;
		}

		@media (max-width: 785px) {
			flex-direction: column;
			width: 100%;
		}

		.slide {
			transform: scale(0.9);
			transition: transform 500ms;

			img {
				width: 100%;
				margin: 10px auto;
				max-width: 400px;
			}
		}

		.activeSlide {
			opacity: 1;
			transition: all 0.3s ease-out;
			transform: scale(1.05);

			@media (max-width: 768px) {
				transform: scale(0.9);
			}
		}

		.slider-image {
			cursor: pointer;
			border-radius: 20px;
		}
	}
}

.card-bullet-points-container {
	.card-bullet-points {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 5px;

		.bullet-points-number {
			background-color: #ffab4d;
			border-radius: 50%;
			margin-inline-end: 20px;
			min-width: 50px;
			display: flex;
			align-items: center;
			min-height: 50px;
			color: white;
			justify-content: center;

			@media (max-width: 910px) {
				margin-inline-end: 5px;
			}
		}

		.learning-objectives-bullet-bg {
			background-color: #2196f3 !important;
		}

		@media (max-width: 1180px) {
			font-size: 18px !important;
		}
	}
}

.learning-objectives-description {
	display: flex;
	flex-direction: column;
}

.color-gray {
	color: #8998a0 !important;
}

.loader {
	width: 48px;
	height: 48px;
	border: 5px solid #fff;
	border-bottom-color: $primary-color;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.row-reverse {
	flex-direction: row-reverse;
}

.image-modal {
	max-width: 700px !important;
	padding: 0 !important;
	background: transparent !important;

	@media (max-width: 500px) {
		max-width: 300px;
	}

	.close-icon {
		font-size: 38px;
		position: absolute;
		top: 0;
		right: 20px;
		cursor: pointer;
	}
}

.modal-content {
	background: white;
	padding: 20px;
	border-radius: 20px;
	width: 350px;

	.modal-header {
		font-size: 16px;
	}

	.modal-input {
		border: 2px solid gray;
		padding: 10px;
		border-radius: 10px;
		margin: 10px 0;
		font-size: 16px;
	}

	.primary-button {
		font-size: 16px;
	}
}