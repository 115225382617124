$primary-color: #0384e5;

.courses-container {
	margin-top: 70px;

	.rtl {
		direction: rtl;
		text-align: right;
	}

	.channel-icon {
		width: 20px;
	}

	.course-duration-level {
		height: 25px;
	}

	.header-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;

		.header-title {
			color: #0384e5;
			letter-spacing: 3px;
			padding: 25px 0 5px 0;
			width: 100%;
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;

			@media (max-width: 500px) {
				font-size: 30px !important;
				text-align: center;
			}
		}

		.sub-header-title {
			color: #757575;
			margin-bottom: 10px;
			line-height: 2rem;
			font-weight: 800;
			font-size: 18px;
			font-family: "Gotham-Light";
			text-align: justify;

			@media (max-width: 500px) {
				text-align: center !important;
			}
		}

		.search-input {
			padding: 10px 20px;
			width: 500px;
			border-radius: 11px;
			border: 0;
			background: #efefef;
			color: black;
			font-size: 18px !important;
			font-family: "Gotham-Light";

			@media (max-width: 500px) {
				width: 100%;
			}
		}
	}

	.courses-grid {
		display: grid !important;
		width: 100%;
		grid-template-columns: repeat(4, 1fr);
		margin: 60px 0;
		padding: 0 50px;

		@media (max-width: 1300px) {
			padding: 0 20px;
		}

		.card {
			padding: 12px;
			border-radius: 20px;
			background: #efefef;
			border: 0;
			box-shadow: 0px 1px 5px 0px #8d81815e;

			.card-image {
				margin-bottom: 10px;
				width: 100%;
				height: 200px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				border-radius: 16px;

				@media (max-width: 1450px) {
					height: 180px;
				}
			}

			.header-1 {
				font-family: "Gotham-light";
				font-size: 18px;
				margin: 5px 0;
				font-weight: 600;
				color: #5a6e7e;
			}

			.header-2 {
				font-family: "Gotham-light";
				font-size: 20px;
				margin: 3px 0;
				font-weight: bold;
				color: #5a6e7e;
				height: 55px;

				@media (max-width: 500px) {
					height: 100%;
				}
			}

			.btn-course-details {
				font-family: "Gotham-light";
				font-size: 18px;
				font-weight: 800;
				margin: 5px 0;
				padding: 11px;
				border-radius: 15px;
				margin-top: 30px !important;
				background-color: #2196f3 !important;
				border: unset !important;
				display: flex;
				justify-content: center;
				gap: 7px;
				align-items: center;
			}

			.btn-course-enroll {
				font-family: "Gotham-Light";
				font-size: 18px;
				font-weight: bold;
				margin: 5px 0;
				padding: 11px;
				border-radius: 15px;
				background: white;
				color: #2196f3;
				border: 1.5px solid #2196f3;
			}

			.content {
				font-family: "Gotham-light";
				font-size: 16px;
				margin: 5px 0;
				font-weight: 400;
				color: #5a6e7e;
				height: 80px;

				@media (max-width: 500px) {
					height: 100%;
				}
			}

			.card-type {
				span {
					display: flex;
					font-family: "Gotham-light";
					font-size: 14px;
					gap: 5px;
					font-weight: 700;
					color: #5a6e7e;
					align-items: center;

					img {
						width: 15px;
					}
				}
			}
		}

		@media (max-width: 1024px) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media (max-width: 800px) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media (max-width: 600px) {
			grid-template-columns: repeat(1, 1fr);
		}
	}
}

.d-flex {
	display: flex !important;
}

.w-50 {
	width: 50% !important;
}

.m-auto {
	margin: auto !important;
}

.card-container {
	margin: 10px;
	padding: 0 15px;
}

.loader {
	width: 48px;
	height: 48px;
	border: 5px solid #fff;
	border-bottom-color: $primary-color;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.ar {
	direction: rtl;
}

.course-container-section {
	display: flex;
	margin: auto;
	width: 50%;

	@media (max-width: 1010px) {
		width: 100%;
	}

	@media (max-width: 500px) {
		padding: 0 20px;
	}
}