$primary-color: #0384e5;

.footer-mb-section-container {
	height: auto;
	margin-top: -15rem;
	position: relative;
	width: 100vw;

	img.footer-mb-Bg {
		width: 100vw;
		height: 67rem;
		position: absolute;
		top: -4rem;
		left: 0;
		right: 0;
		z-index: -1;
	}

	.footer-mb-section {
		padding-top: 15rem;
	}

	.footer-mb-links {
		display: block;
		justify-content: flex-end;
		margin: 5rem 10%;
		@media (max-width: 500px) {
			width: 100%;
			margin: 0;
			padding: 2rem;
		}

		.link {
			margin: auto;
			width: 100%;
			padding: 0;

			.link-image {
				width: 100%;
				max-width:200px;
				@media (max-width: 500px) {
					width: 40%;
					margin-bottom: 5%;
				}
			}
		}

		.title {
			font-size: 2.5rem;
			font-weight: bold;
			color: $primary-color;
			margin-bottom: 3rem;
			@media (max-width: 500px) {
				margin-top: 3rem;
				margin-bottom: 0;
			}
		}

		.links {
			color: $primary-color;
			font-family: Gotham-light;
			line-height: 2.5rem;
			list-style: none;
			font-size: 1.5rem;
			cursor: pointer;
			li {
				a {
					color: $primary-color;
					line-height: 2.5rem;
					list-style: none;
					text-decoration: none !important;
					font-size: 1.5rem;
				}
			}
		}
	}

	.footer-mb-end {
		background-color: $primary-color;
		width: 100%;
		.social-copyrights {
			margin: auto;
			padding: 2rem 0;
			p {
				color: white;
				text-align: center;
				font-weight: lighter;
				font-size: 1.4rem;
			}
			@media (max-width: 500px) {
				padding: 1rem;
			}
		}
	}
}

.socials {
	width: 100%;
	display: flex;
	justify-content: start;
	margin-bottom: 5rem;
	padding: 0 10%;

	.socialsimg {
		margin-right: 1rem;
		width: 3rem;
		height: 3rem;
	}
	@media (max-width: 500px) {
		padding: 0 2rem;
		margin-bottom: 10% !important;
	}
	@media (max-width: 768px) {
		padding: 0 5%;
		margin-bottom: 5%;
	}
}

.copyright {
	font-size: 2rem;
	color: var(--darkBlue);
}
